import React, {useEffect, useState} from 'react';
import {Container, Navbar, Row, Col} from "react-bootstrap";
import CheckoutBtnHeader from "../components/Header/CheckoutBtnHeader";
import TopHeader from "../components/Header/TopHeader";
import {Link} from 'react-router-dom';

const Header: React.FC = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', updateStickyMenu);
  });

  const updateStickyMenu = () => {
    const height = window.pageYOffset;
    if (height > 40) {
      setIsSticky(true);
      return;
    }

    setIsSticky(false);
  };

  return (
    <React.Fragment>
      <TopHeader/>
      <div className={`header-bg ${isSticky ? 'sticky-top' : ''} `}>
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col md={{span: 11, offset: 1}}>
              {/*<Link to='/' className='home-link'>*/}
                <Navbar.Brand href='/'>
                  LOGO
                </Navbar.Brand>
              {/*</Link>*/}

              <CheckoutBtnHeader/>
            </Col>
            {/*<Col md={{span: 5, offset: 1}}>*/}
            {/*    <Checkout/>*/}
            {/*</Col>*/}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Header;