import React, {useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import AddressOther from "./AddressOther";
import {useDispatch} from "react-redux";
import {setIsSameBillingAddress} from "../../store/actions/bookingActions";

type CardAddressProps = {
  isValidated: boolean
}

const CardAddress: React.FC<CardAddressProps> = (props) => {
  const [isSameAddress, setIsSameAddress] = useState(true);
  
  const {isValidated} = props;
  const dispatch = useDispatch();

  const handleSetAddressOption = (isSame: boolean) => {
    setIsSameAddress(isSame);
    dispatch(setIsSameBillingAddress(isSame));
  };

  return (
    <Row>
      <Col xs={12} className='pt-4'>
        <p className='address-title'>Change Shipping Address</p>
      </Col>
      <Col xs={12} className='mb-3'>
        <Form.Check
          type='radio'
          inline
          name='address-group'
          label='Same as user address'
          id='same'
          className='form-check'
          custom
          defaultChecked={isSameAddress}
          onClick={() => handleSetAddressOption(true)}
        />
        <Form.Check
          type='radio'
          inline
          name='address-group'
          label='Change shipping address'
          id='other'
          className='form-check'
          custom
          onClick={() => handleSetAddressOption(false)}
        />

        {/*<Form.Control.Feedback*/}
        {/*  className={isValidated ? 'dis-block' : ''}*/}
        {/*  type="invalid">*/}
        {/*  Please select an option*/}
        {/*</Form.Control.Feedback>*/}
      </Col>

      {!isSameAddress && <Col xs={12}><AddressOther isValidated={isValidated}/></Col>}

    </Row>
  );
};

export default CardAddress;