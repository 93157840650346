import {Item} from "../types/itemStoreTypes";

export const products: Item[]
  = [
  {
    id: 1,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/coconut.jpg',
    name: 'Coconut',
    price: 65.00,
    discount: 90.00,
    qty: 0
  },
  {
    id: 2,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/garlic.jpg',
    name: 'garlic',
    price: 550.00,
    discount: 560.00,
    qty: 0
  },
  {
    id: 3,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/highland-milk.jpg',
    name: 'milk powder',
    price: 370.00,
    discount: 380.00,
    qty: 0
  },
  {
    id: 4,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/onions.jpg',
    name: 'onions',
    price: 125.00,
    discount: 150.00,
    qty: 0
  },
  {
    id: 6,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/dhal.png',
    name: 'Dhal',
    price: 200.99,
    discount: 400.00,
    qty: 0
  },
  {
    id: 7,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/cinnamon.jpg',
    name: 'cinnamon',
    price: 225.00,
    discount: 250.00,
    qty: 0
  },
  {
    id: 8,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/chickpea.jpg',
    name: 'chickpea',
    price: 225.00,
    discount: 250.00,
    qty: 0
  },
  {
    id: 9,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/cream_cracker.jpg',
    name: 'Cream Cracker',
    price: 125.00,
    discount: 150.00,
    qty: 0
  },
];

export const productsFruits: Item[]
  = [
  {
    id: 21,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/almonds.jpg',
    name: 'almonds',
    price: 75.00,
    discount: 90.00,
    qty: 0
  },
  {
    id: 22,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/avacado.jpg',
    name: 'avacado',
    price: 65.00,
    discount: 90.00,
    qty: 0
  },
  {
    id: 23,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/banana.jpg',
    name: 'banana',
    price: 195.00,
    discount: 290.00,
    qty: 0
  },
  {
    id: 24,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/grapes.jpg',
    name: 'grapes',
    price: 395.00,
    discount: 490.00,
    qty: 0
  },
  {
    id: 25,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/green_grapes.jpg',
    name: 'green grapes',
    price: 395.00,
    discount: 490.00,
    qty: 0
  },
  {
    id: 26,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/kiwi.jpg',
    name: 'kiwi',
    price: 295.00,
    discount: 390.00,
    qty: 0
  },
  {
    id: 27,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/papaya.jpg',
    name: 'papaya',
    price: 95.00,
    discount: 90.00,
    qty: 0
  },
  {
    id: 28,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/pineapple.jpg',
    name: 'pineapple',
    price: 110.00,
    discount: 190.00,
    qty: 0
  },
  {
    id: 29,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/mango.jpg',
    name: 'mango',
    price: 55.00,
    discount: 90.00,
    qty: 0
  },
  {
    id: 30,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/strawberry.jpg',
    name: 'strawberry',
    price: 55.00,
    discount: 90.00,
    qty: 0
  },


];

export const productsVegetables: Item[]
  = [
  {
    id: 41,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/beetroots.jpg',
    name: 'beetroots',
    price: 75.00,
    discount: 90.00,
    qty: 0
  },
  {
    id: 42,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/broccoli.jpg',
    name: 'broccoli',
    price: 175.00,
    discount: 290.00,
    qty: 0
  },
  {
    id: 43,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/corn.jpg',
    name: 'corn',
    price: 25.00,
    discount: 30.00,
    qty: 0
  },
  {
    id: 44,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/eggplant.jpg',
    name: 'eggplant',
    price: 155.00,
    discount: 230.00,
    qty: 0
  },
  {
    id: 45,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/lime.jpg',
    name: 'lime',
    price: 125.00,
    discount: 230.00,
    qty: 0
  },
  {
    id: 46,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/red_chili.jpg',
    name: 'red chili',
    price: 230.00,
    discount: 330.00,
    qty: 0
  },
  {
    id: 47,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/grocery/tomato.jpg',
    name: 'tomato',
    price: 60.00,
    discount: 80.00,
    qty: 0
  },

];


export const productsPharmacy: Item[]
  = [
  {
    id: 200,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/dettol.jpg',
    name: 'Dettol 30ml',
    price: 85.00,
    discount: 90.00,
    qty: 0
  },
  {
    id: 201,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/handwash.jpg',
    name: 'Hand wash skin care',
    price: 200.00,
    discount: 234.00,
    qty: 0
  },
  {
    id: 202,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/baby_diapers.jpg',
    name: 'baby diapers',
    price: 300.00,
    discount: 334.00,
    qty: 0
  },
  {
    id: 203,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/codliver_oil.jpg',
    name: 'codliver oil',
    price: 100.00,
    discount: 134.00,
    qty: 0
  },
  {
    id: 204,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/detola_sanitizer.jpg',
    name: 'dettol sanitizer',
    price: 220.00,
    discount: 234.00,
    qty: 0
  },
  {
    id: 205,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/face_masks.jpg',
    name: 'face masks',
    price: 90.00,
    discount: 200.00,
    qty: 0
  },
  {
    id: 206,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/farley_rusks.jpg',
    name: 'farley rusks',
    price: 190.00,
    discount: 200.00,
    qty: 0
  },
  {
    id: 207,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/gauze.jpg',
    name: 'gauze',
    price: 40.00,
    discount: 60.00,
    qty: 0
  },
  {
    id: 208,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/glucometer.jpg',
    name: 'glucometer',
    price: 1540.00,
    discount: 2660.00,
    qty: 0
  },
  {
    id: 209,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/nan.jpg',
    name: 'nan',
    price: 440.00,
    discount: 560.00,
    qty: 0
  },
  {
    id: 210,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/pampers.jpg',
    name: 'pampers',
    price: 240.00,
    discount: 260.00,
    qty: 0
  },
  {
    id: 211,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/safety_pins.jpg',
    name: 'safety pins',
    price: 40.00,
    discount: 70.00,
    qty: 0
  },
  {
    id: 212,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/scissors.jpg',
    name: 'scissors',
    price: 440.00,
    discount: 700.00,
    qty: 0
  },
  {
    id: 213,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/thermometer.jpg',
    name: 'thermometer',
    price: 640.00,
    discount: 700.00,
    qty: 0
  },
    {
    id: 213,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/pharmacy/wheelchair.jpg',
    name: 'wheelchair',
    price: 8640.00,
    discount: 9700.00,
    qty: 0
  },


];

export const productsFood: Item[]
  = [
  {
    id: 100,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/food/chicken_burgur.jpg',
    name: 'Burger',
    price: 385.00,
    discount: 490.00,
    qty: 0
  },
  {
    id: 101,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/food/creemy_cheese_sasuage_pizza.jpg',
    name: 'Pizza',
    price: 690.00,
    discount: 750.00,
    qty: 0
  },
  {
    id: 102,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/food/grilled_sausages.jpg',
    name: 'Grill Sausages',
    price: 190.00,
    discount: 250.00,
    qty: 0
  },
  {
    id: 103,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/food/ham_and_cheese_submarine.jpg',
    name: 'Submarine',
    price: 390.00,
    discount: 450.00,
    qty: 0
  },
  {
    id: 104,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/food/prawn_pasta.jpg',
    name: 'Pasta',
    price: 190.00,
    discount: 250.00,
    qty: 0
  },
  {
    id: 105,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/food/smoked_fish.jpg',
    name: 'Smoked Fish',
    price: 690.00,
    discount: 750.00,
    qty: 0
  },
  {
    id: 106,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/food/Staek_with_mushrooms.jpg',
    name: 'Mushroom',
    price: 250.00,
    discount: 350.00,
    qty: 0
  },
  {
    id: 107,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/food/vegetable_rice.jpg',
    name: 'Vegi Fried Rice',
    price: 300.00,
    discount: 350.00,
    qty: 0
  },


];

export const productsCake: Item[]
  = [
  {
    id: 110,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/cakes/choclate_muffins.jpg',
    name: 'Chocolate Muffins',
    price: 85.00,
    discount: 90.00,
    qty: 0
  },
  {
    id: 111,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/cakes/coffee_pudding.jpg',
    name: 'Coffee Pudding',
    price: 85.00,
    discount: 90.00,
    qty: 0
  },
  {
    id: 112,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/cakes/mirror_glazed_cake.jpg',
    name: 'Mirror Glazed Cake',
    price: 485.00,
    discount: 590.00,
    qty: 0
  },
  {
    id: 113,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/cakes/naked_cake.jpg',
    name: 'Naked Cake',
    price: 285.00,
    discount: 390.00,
    qty: 0
  },
  {
    id: 114,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/cakes/strawberry_shortcake.jpg',
    name: 'Strawberry Shortcake',
    price: 485.00,
    discount: 590.00,
    qty: 0
  },
  {
    id: 115,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/cakes/Sugar buns.jpg',
    name: 'Sugar buns',
    price: 155.00,
    discount: 190.00,
    qty: 0
  },
  {
    id: 116,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/cakes/Tirramusu.jpg',
    name: 'Tirramusu',
    price: 125.00,
    discount: 170.00,
    qty: 0
  },
  {
    id: 116,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/cakes/Vannila cupcakes.jpg',
    name: 'Vannila cupcakes',
    price: 90.00,
    discount: 100.00,
    qty: 0
  },


];

export const productsElectronics: Item[]
  = [
  {
    id: 300,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/doughnut.jpg',
    name: 'doughnut maker',
    price: 3085.00,
    discount: 3390.00,
    qty: 0
  },
  {
    id: 301,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/burner.jpg',
    name: 'Gas burner',
    price: 10085.00,
    discount: 13390.00,
    qty: 0
  },
  {
    id: 302,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/air_conditioner.jpg',
    name: 'air conditioner',
    price: 60000.00,
    discount: 83000.00,
    qty: 0
  },
  {
    id: 303,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/ceiling_fan.jpg',
    name: 'ceiling fan',
    price: 6000.00,
    discount: 8000.00,
    qty: 0
  },
  {
    id: 304,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/electric_oven.jpg',
    name: 'electric oven',
    price: 11000.00,
    discount: 13000.00,
    qty: 0
  },
  {
    id: 305,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/floor_polisher.jpg',
    name: 'floor polisher',
    price: 18000.00,
    discount: 25000.00,
    qty: 0
  },
  {
    id: 306,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/food_processor.jpg',
    name: 'food processor',
    price: 12000.00,
    discount: 15000.00,
    qty: 0
  },
  {
    id: 307,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/grinder.jpg',
    name: 'grinder',
    price: 8000.00,
    discount: 19000.00,
    qty: 0
  },
  {
    id: 308,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/hot_plate.jpg',
    name: 'hot plate',
    price: 1800.00,
    discount: 2500.00,
    qty: 0
  },
  {
    id: 309,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/nonstick_pan.jpg',
    name: 'nonstick pan',
    price: 1600.00,
    discount: 2500.00,
    qty: 0
  },
  {
    id: 310,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/panni_press.jpg',
    name: 'panni press',
    price: 11800.00,
    discount: 12500.00,
    qty: 0
  },
  {
    id: 311,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/table_fan.jpg',
    name: 'table fan',
    price: 2800.00,
    discount: 4500.00,
    qty: 0
  },
  {
    id: 312,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/television.jpg',
    name: 'television',
    price: 22800.00,
    discount: 45000.00,
    qty: 0
  },
  {
    id: 313,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/toaster.jpg',
    name: 'toaster',
    price: 2800.00,
    discount: 4500.00,
    qty: 0
  },
  {
    id: 314,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/waffel_maker.jpg',
    name: 'waffel maker',
    price: 3700.00,
    discount: 5500.00,
    qty: 0
  },
  {
    id: 314,
    itemPhoto: 'https://s3.amazonaws.com/cdn1.shub/electro/washin_machine.png',
    name: 'washing machine',
    price: 77000.00,
    discount: 155000.00,
    qty: 0
  },



];

