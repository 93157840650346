import {COMPANY_WEBSITE, COMPANY_WEBSITE_AU} from "../constants/values";

export const getCurrencyLabel = (currency: string) => {
  switch (currency) {
    case "LKR":
      return 'Rs.';
    // case "LKR": return 'Rs.';
    case "AUD":
      return '$';
    default:
      return '$';
  }
};

export const getPricing = (currency: string, isAnnualPlan: boolean) => {
  if (isAnnualPlan) {
    switch (currency) {
      case "LKR":
        return {p1: 'LKR 2,900',p2: 'LKR 4,900',p3: 'LKR 11,900'};
      case "AUD":
        return {p1: 'AUD 39',p2: 'AUD 59',p3: 'AUD 99'};
      default:
        return {p1: '$ 29',p2: '$ 39',p3: '$ 69'};
    }
  }

  switch (currency) {
    case "LKR":
      return {p1: 'LKR 3,290',p2: 'LKR 5,590',p3: 'LKR 13,200'};
    case "AUD":
      return {p1: 'AUD 44',p2: 'AUD 66',p3: 'AUD 110'};
    default:
      return {p1: '$ 33',p2: '$ 44',p3: '$ 77'};
  }
};

export const getPhoneNumber = (countryName: string) => {
  if (countryName === "Australia") {
    return '+61470 412 956';
  }
  return '+94779 510 260';
};

export const getCompanyWebsite = (countryName: string) => {
  if (countryName === "Australia") {
    return COMPANY_WEBSITE_AU;
  }
  return COMPANY_WEBSITE;
};

