import React from 'react';
import {Button, Navbar, Dropdown} from 'react-bootstrap';
import { Link } from "react-router-dom";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import {Item} from "../../types/itemStoreTypes";
import {useSelector} from "react-redux";
import {AppState} from "../../store/reducers";

const CheckoutBtnHeader: React.FC = () => {
    const items: Item[] = useSelector((state: AppState) => state.item.items);

    return (
        <React.Fragment>
            <div className="cart-dropdown cart-menu">
                <Navbar >
                    <div className="cart-count">{items.length}</div>
                    <div className="dropdown-btn-icon">
                        <Dropdown id="nav-dropdown">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <i className="feather icon-shopping-cart"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="4.1" >
                                    <ShoppingCart/>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="checkout-btn">
                        <Link to='/checkout'>
                            <Button>Checkout</Button>
                        </Link>
                    </div>
                </Navbar>
            </div>
        </React.Fragment>
    )
};


export default CheckoutBtnHeader;