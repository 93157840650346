import React, {useState} from 'react';
import {Accordion, Card, Col, Row} from "react-bootstrap";

type FaqCompProps = {
    title: string
    description: string
}

const FaqComp: React.FC<FaqCompProps> = (props) => {
    const {title, description} = props;
    const [isVisible, setIsVisible] = useState(false);

    return (
        <React.Fragment>
            <Row>
                <Col lg={{span: 10, offset: 1}} className='mb-3 mt-3 faq-item'>
                    <Accordion defaultActiveKey="1">
                        <Card className='card-bg'>
                            <Accordion.Toggle onClick={() => setIsVisible(!isVisible)} as={Card.Header} eventKey="0">
                                {title}
                                <i className={`feather ${isVisible ? 'icon-arrow-up' : 'icon-arrow-down'}`}/>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: description
                                        }} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default FaqComp;