import React from 'react';
import {Accordion, Button, Card, Container} from "react-bootstrap";
import FaqComp from "../components/FAQ/FaqComp";
import {Item} from "../types/itemStoreTypes";
import {useSelector} from "react-redux";
import {AppState} from "../store/reducers";
import {faqs} from "../constants/values";

const FAQ: React.FC = () => {
    const currency: string = useSelector((state: AppState) => state.item.geo.currency);

    return (
        <React.Fragment>
            <div className="faq-header faq-header-radius text-center">
                <h1>FAQ</h1>
            </div>
            <Container>
                {
                    faqs.map((faq, index) => {
                      if((currency !== 'LKR' && index === 7) || (currency === 'LKR' && index === 8)) {
                          return;
                      }
                      return <FaqComp title={faq.title} description={faq.description} key={index}/>
                    })
                }
            </Container>
        </React.Fragment>
    )
};

export default FAQ;