import React from 'react';
import {Item} from "../../../types/itemStoreTypes";
import {useDispatch} from "react-redux";
import {changeItem} from "../../../store/actions/itemActions";

type EditableQtyProps = {
    item: Item
}

const EditableQty: React.FC<EditableQtyProps> = (props) => {
    const dispatch = useDispatch();

    const {item} = props;

    const handleAddQty = () => {
        dispatch(changeItem({...item, qty: item.qty + 1}));
    };

    const handleMinusQty = () => {
        if (item.qty > 1) {
            dispatch(changeItem({...item, qty: item.qty - 1}));
        }
    };

    return (
        <div className='qty-editor'>
            <i className='feather icon-minus-circle' onClick={handleMinusQty} />
            <label>{item.qty}</label>
            <i className='feather icon-plus-circle' onClick={handleAddQty} />
        </div>
    )
};

export default EditableQty;