export const BILLING_ADDRESS_PERSONAL = 'personal';
export const BILLING_ADDRESS_DIFFERENT = 'different';

export const countries = [
  {value: 'USA', label: 'United States of America'},
  {value: 'AU', label: 'Australia'},
  {value: 'LK', label: 'Sri Lanka'},
  {value: 'SG', label: 'Singapore'},
  {value: 'IN', label: 'India'},
];

export const banks = [
  {value: 'Sampath', label: 'Sampath Bank'},
  {value: 'HNB', label: 'HNB'},
  {value: 'Commercial', label: 'Commercial Bank of Ceylon'},
  {value: 'HSBC', label: 'HSBC'},
  {value: 'NTB', label: 'Nations Trust Bank'},
];

export const findCountryByValue = (countryValue: string) => {
  return countries.find(country => country.value === countryValue);
};

export const findBankByValue = (bankValue: string) => {
  return banks.find(bank => bank.value === bankValue);
};

export const INIT_VISITOR_COUNTRY = 'US';
export const COUNTRY_AU = 'Australia';

export const COMPANY_WEBSITE = 'http://softvessel.com';
export const COMPANY_WEBSITE_AU = 'http://softvessel.com.au';

export const faqs
  = [
  {
    id: 1,
    title: 'How much does a new website cost?',
    description: '<p>Please see <a href="/pricing" rel="noopener noreferrer" target="_blank">our pricing page</a> for packages and select a plan that suits well with you.</p>'
  },
  {
    id: 2,
    title: 'How long will it take to get my busniness online?',
    description: '<p>On average, we shoot for a six to seven days for the pricing plan 1 and 2.&nbsp;</p>\n' +
      '<p>How much input you can provide during the initial stages, your availability with feedback, how soon the content is ready &ndash; all this affects the speed of completion. The 3rd pricing plan will take one month to complete.</p>\n' +
      '<p>More complex customizations will take more time to develop.</p>'
  },
  {
    id: 3,
    title: 'Can you do customizations according to my requirements? Like the design?',
    description: '<p>Yes, we can.&nbsp;</p>\n' +
      '<p>Every website is unique and requires different components. We design and develop custom websites specifically for your business. We&rsquo;ll ask a lot of questions, assess your needs, and give you a quote based on that assessment.</p>'
  },
  {
    id: 4,
    title: 'Will I be able to update products myself when it’s finished?',
    description: '<p>Yes! We love it when our clients take control of their website and learn to manage it on their own.&nbsp;</p>\n' +
      '<p>We&rsquo;ll provide you with a training session to help get you on your way.(Note: We love to answer your questions!)</p>'
  },
  {
    id: 5,
    title: 'When do I pay?',
    description: 'We take an up-front payment of 50% based on your selected package once you do the confirmation. After the website is online,\n' +
      'the full payment will be charged.'
  },
  {
    id: 6,
    title: 'I’m on a strict budget. Do you have any low cost options?',
    description: 'Yes, Please visit our pricing page and select a plan that suits well with you.'
  },
  {
    id: 7,
    title: 'Can my customers order using android/apple?',
    description: 'Yes, of course. We have developed our website in a way that they can view and do the order confirmations using their mobile device.'
  },
  {
    id: 8,
    title: 'Are there any additional costs with Website?',
    description: "<p>Following charges will be applied annually to each packages for domain/hosting charges and for general website maintenance.</p>\n" +
      "\n" +
      "<p><strong>Domain and Hosting Charge:</strong><br>" +
      "Normally it's $18.17 annually for a .com domain name. But this can vary depend on the name you choose.<br/><br/>" +
      " A domain name is unique to your business, appearing in your website&rsquo;s URL. Without a domain name, it&rsquo;s impossible for users to visit your site, which is why you must pay to renew your domain name. If you don&rsquo;t, someone else could purchase the name and force your company to find a new one.<br>Like your domain name, website hosting is essential. If you don&rsquo;t have a hosting provider, it&rsquo;s impossible for people to access your website. That&rsquo;s why your business needs to include website hosting costs.</p>\n"
  },
  {
    id: 8,
    title: 'Are there any additional costs with Website?',
    description: "<p>Following charges will be applied annually to each packages for domain/hosting charges and for general website maintenance.</p>\n" +
      "\n" +
      "<p><strong>Domain and Hosting Charge:</strong><br>" +
      "Normally it's $18.17 annually for a .com domain name. But this can vary depend on the name you choose.<br/><br/>" +
      " A domain name is unique to your business, appearing in your website&rsquo;s URL. Without a domain name, it&rsquo;s impossible for users to visit your site, which is why you must pay to renew your domain name. If you don&rsquo;t, someone else could purchase the name and force your company to find a new one.<br>Like your domain name, website hosting is essential. If you don&rsquo;t have a hosting provider, it&rsquo;s impossible for people to access your website. That&rsquo;s why your business needs to include website hosting costs.</p>\n"
  },

  // {
  //     id: 8,
  //     title: 'Are there any additional costs with Website?',
  //     description: "<p>Following charges will be applied annually to each packages for domain/hosting charges and for general website maintenance.</p>\n" +
  //       "\n" +
  //       "<p><strong>Domain and Hosting Charge:</strong><br>" +
  //       "Normally it's $18.17 annually for a .com domain name. But this can vary depend on the name you choose.<br/><br/>" +
  //       " A domain name is unique to your business, appearing in your website&rsquo;s URL. Without a domain name, it&rsquo;s impossible for users to visit your site, which is why you must pay to renew your domain name. If you don&rsquo;t, someone else could purchase the name and force your company to find a new one.<br>Like your domain name, website hosting is essential. If you don&rsquo;t have a hosting provider, it&rsquo;s impossible for people to access your website. That&rsquo;s why your business needs to include website hosting costs.</p>\n" +
  //       "<p><strong>GENERAL WEBSITE UPDATES and maintenance:</strong><br/>" +
  //       "<span>STARTER: Rs. 5,900/= per year<br>PREMIUM: Rs. 10,900/= per year<br>ULTIMATE: Rs. 15,490/= per year</span><br/><br/>" +
  //       "Website maintenance is a routine process of inspecting and updating your website to industry standards.<br>Also to make sure your website adheres to industry standards for security and data protection. This process is critical since we support online transactions.</p>"
  // },
  // {
  //     id: 8,
  //     title: 'Are there any additional costs with Website?',
  //     description: "<p>Following charges will be applied annually to each packages for domain/hosting charges and for general website maintenance.</p>\n" +
  //       "\n" +
  //       "<p><strong>Domain and Hosting Charge:</strong><br>" +
  //       "Normally it's $18.17 annually per year for a .com domain name. But this can vary depend on the name you choose.<br/><br/>" +
  //       " A domain name is unique to your business, appearing in your website&rsquo;s URL. Without a domain name, it&rsquo;s impossible for users to visit your site, which is why you must pay to renew your domain name. If you don&rsquo;t, someone else could purchase the name and force your company to find a new one.<br>Like your domain name, website hosting is essential. If you don&rsquo;t have a hosting provider, it&rsquo;s impossible for people to access your website. That&rsquo;s why your business needs to include website hosting costs.</p>\n" +
  //       "<p><strong>GENERAL WEBSITE UPDATES and maintenance:</strong><br/>" +
  //       "<span>STARTER: $99 per year<br>PREMIUM: $199 per year<br>ULTIMATE: $290/= per year</span><br/><br/>" +
  //       "Website maintenance is a routine process of inspecting and updating your website to industry standards.<br>Also to make sure your website adheres to industry standards for security and data protection. This process is critical since we support online transactions.</p>"
  // },
];


