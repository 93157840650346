import React from 'react';
import {Col, Container, Image, Row} from 'react-bootstrap';
import Product from "./Product";
import {
  products,
  productsCake, productsElectronics,
  productsFood,
  productsFruits,
  productsPharmacy,
  productsVegetables
} from "../../constants/products";
import {Item} from "../../types/itemStoreTypes";
import {useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import banner1 from '../../assets/images/pay with credit card.png';
import contactBanner from '../../assets/images/contact.png';
import contactBannerAU from '../../assets/images/contact_au.png';
import discount from '../../assets/images/discount.png';
import ImageFileUpload from "../ImageFileUpload/ImageFileUpload";
import {COUNTRY_AU} from "../../constants/values";

const Products: React.FC = () => {
  const itemsInCart: Item[] = useSelector((state: AppState) => state.item.items);
  //category selected.
  const category: string | null = useSelector((state: AppState) => state.item.category);
  const countryName: string = useSelector((state: AppState) => state.item.geo.countryName);

  const listProducts = () => {
    return products.map((item: Item, index: number) => {
      return (
        <Product item={item} key={index} itemsInCart={itemsInCart}/>
      );
    });
  };

  const listFruits = () => {
    return productsFruits.map((item: Item, index: number) =>
      <Product item={item} key={index} itemsInCart={itemsInCart}/>);
  };

  const listVegi = () => {
    return productsVegetables.map((item: Item, index: number) =>
      <Product item={item} key={index} itemsInCart={itemsInCart}/>);
  };

  const listFood = () => {
    return productsFood.map((item: Item, index: number) => <Product item={item} key={index}
                                                                    itemsInCart={itemsInCart}/>);
  };

  const listCakes = () => {
    return productsCake.map((item: Item, index: number) => <Product item={item} key={index}
                                                                    itemsInCart={itemsInCart}/>);
  };

  const listElectronics = () => {
    return productsElectronics.map((item: Item, index: number) => <Product item={item} key={index}
                                                                           itemsInCart={itemsInCart}/>);
  };

  const getContactBanner = () => {
    if (countryName === COUNTRY_AU) {
      return (
        <a href='http://softvessel.com.au/contact' target="_blank">
          <img src={contactBannerAU} alt='contact us'/></a>
      );
    }

    return (
      <a href='http://softvessel.com/contact' target="_blank">
        <img src={contactBanner} alt='contact us'/></a>
    );
  };

  const getGrocery = () => {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <h5 className='category-title'>Grocery</h5>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Row>
              {listProducts()}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={8} xs={12}>
            <div className="banner1">
              <img src={banner1} alt='pay with credit card'/>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <h5 className='category-title'>Fruits</h5>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Row>
              {listFruits()}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <h5 className='category-title'>Vegetables</h5>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Row>
              {listVegi()}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <div className="contact-banner">
              <a
                href='https://optin.spencehub.com/nextgen-web-store-that-generate-predictable-income-sri-lanka?sid=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IiIsIlNDIjoic3RvcmUiLCJERCI6IjVlZDBkZmNkNDFjY2RkMmI1NjQ5YmUyYyIsIlNUIjoxNTkwNzQ3MDg1Njk5LCJpYXQiOjE1OTA3NDcwODV9.Qe3lApEJ0xCjbeN24nHs7hfoXP2s1usPW_pFGQOJn1Y'
                target="_blank"><img src={discount} alt='Get an Offer'/></a>
            </div>
          </Col>

          <Col lg={6}>
            <div className="contact-banner">
              {getContactBanner()}
            </div>
          </Col>
        </Row>

        {/*<Row>*/}
        {/*  <Col xs={12}>*/}
        {/*    <Image src='https://s3.amazonaws.com/cdn1.shub/res/creditcard.jpg'*/}
        {/*           className='banner'/>*/}
        {/*  </Col>*/}
        {/*</Row>*/}

      </React.Fragment>
    );
  };

  const listPharmacyProducts = () => {
    return productsPharmacy.map((item: Item, index: number) =>
      <Product item={item} key={index} itemsInCart={itemsInCart}/>
    );
  };


  const getPharmacy = () => {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <h5 className='category-title'>Pharmacy</h5>
          </Col>
        </Row>

        <ImageFileUpload/>

        <Row>
          <Col xs={12} className='mt-5'>
            <h6 className=''>Add to your order</h6>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Row>
              {listPharmacyProducts()}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={{span: 8, offset: 4}} xs={12}>
            <div className="contact-banner">
              {getContactBanner()}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  const getFood = () => {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <h5 className='category-title'>Food & Pastries</h5>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Row>
              {listFood()}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h5 className='category-title'>Cakes</h5>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Row>
              {listCakes()}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={{span: 8, offset: 4}} xs={12}>
            <div className="banner1">
              <img src={banner1} alt='pay with credit card'/>
            </div>
          </Col>
        </Row>

      </React.Fragment>
    );
  };

  const getElectronics = () => {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <h5 className='category-title'>Electronics</h5>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Row>
              {listElectronics()}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <div className="contact-banner">
              <a
                href='https://optin.spencehub.com/nextgen-web-store-that-generate-predictable-income-sri-lanka?sid=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IiIsIlNDIjoic3RvcmUiLCJERCI6IjVlZDBlMGExOWM4ZDY4MmM0NDZjZWViZCIsIlNUIjoxNTkwNzQ3Mjk3NDUzLCJpYXQiOjE1OTA3NDcyOTd9.ZWT5xeZ8tWzK2Olm4Af4PEMq_l-mfVYLdjJ5ToWnZTk'
                target="_blank"><img src={discount} alt='Get an Offer'/></a>
            </div>
          </Col>
          <Col lg={6}>
            <div className="contact-banner">
              {getContactBanner()}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  return (
    <Container className="flex-fill justify-content-around product-title mt-2 mb-5">
      {(category === null || category === 'grocery') && getGrocery()}

      {(category === null || category === 'pharmacy') && getPharmacy()}

      {(category === null || category === 'food') && getFood()}

      {(category === null || category === 'electronic') && getElectronics()}
    </Container>
  );
};

export default Products;