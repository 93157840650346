import React, {useEffect, useState} from 'react';
import {Card, Form} from "react-bootstrap";
import BillingInfoPersonal from "./BillingInfoPersonal";
import BillingInfoRegister from "./BillingInfoRegister";
import CardDetails from "./CardDetails";
import CardAddress from "./CardAddress";
import {useSelector} from "react-redux";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {AppState} from "../../store/reducers";
import {GET_LAST_BILLING_INFO} from "../../graphql/query/bookingQuery";

type BillingInfoProps = {
  isGuest: boolean
}

const BillingInfo: React.FC<BillingInfoProps> = (props) => {
  const validated = useSelector((state: AppState) => state.booking.isValidated);
  const {isGuest} = props;

  // const [getBillingInfo, {loading, data, error}] = useLazyQuery(GET_LAST_BILLING_INFO);

  // if (data) {
    // const {__typename, ...lastBillingInfo} = data.lastBillingInfo;
    // console.log(data.lastBillingInfo);
  // }

  const getLastBillingInfo = (data: any) => {
    const {__typename, ...lastBillingInfo} = data.lastBillingInfo;
    return lastBillingInfo;
  };

  // if (error) {
  //   console.log('Problem loading last billing info.');
  // }

  useEffect(() => {
    //requesting previous billing info for non guest users.
    if (!isGuest) {
      // getBillingInfo()
    }
  }, [isGuest]);

  return (
    <React.Fragment>
      <Card className='payment-card'>
        <Card.Header>
          <Card.Title>
            Shipping and Billing Address
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} className='billing-info' >
            <BillingInfoPersonal isValidated={validated} lastBillingInfo={null}/>
            {/*{loading && <p>Loading...</p>}*/}

            {isGuest && <BillingInfoRegister isValidated={validated}/>}

            <div className='separater mt-4'/>


            {/*<CardDetails />*/}
          </Form>
        </Card.Body>
      </Card>
      <div className="billing-info">
        <CardAddress isValidated={validated}/>
      </div>
    </React.Fragment>
  );
};

export default BillingInfo;