import React, {useEffect, useState} from 'react';
import {Route, Switch} from "react-router";
import indexRoutes from "../routes";
import Header from "./Header";
import Footer from "./Footer";
import {useDispatch, useSelector} from "react-redux";
import {Geo} from "../types/itemStoreTypes";
import {AppState} from "../store/reducers";
import axios from 'axios';
import {INIT_VISITOR_COUNTRY} from "../constants/values";
import {setGeo} from "../store/actions/itemActions";

const PageLayout: React.FC = () => {
  const dispatch = useDispatch();
  const geo: Geo = useSelector((state: AppState) => state.item.geo);

  const getGeo = () => {
    axios.get('https://ipapi.co/json/').then( (response) => {
      // console.log(response);
      const country = response.data.country_name;
      const currency = response.data.currency;
      const callCode = response.data.country_calling_code;
      const countryCode = response.data.country_code;

      const userGeo: Geo = {countryName: country, currency: currency, callingCode: callCode, countryCode: countryCode};
      dispatch(setGeo(userGeo));
    })
      .catch((error) => {
        // handle error
        console.log(error);
      })
  };

  useEffect(() => {
    if (geo.countryName === INIT_VISITOR_COUNTRY) {
      getGeo();
    }
  }, []);

  return (
    <React.Fragment>
      <Header/>
      <Switch>
        {indexRoutes.map((route, i) => {
          return (
            <Route key={i} path={route.path} component={route.component}/>
          );
        })}
      </Switch>
      <Footer/>
    </React.Fragment>
  );
};

export default PageLayout;