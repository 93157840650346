import { IRoute } from "../interface/IRoute";
import { FE_ROUTES } from "../constants/routes";
import Home from "../layout/Home";
import DemoHome from "../layout/DemoHome";
import FAQ from "../pages/FAQ";
import AboutUs from "../pages/About-us";
import Contact from "../pages/Contact";
import MyAccount from "../pages/MyAccount";
import Register from "../pages/Register";
import Login from "../pages/Login";
import Checkout from "../components/Checkout/Checkout";
import Pricing from "../pages/Pricing";
import ThankYou from "../components/ThankYou/ThankYou";

const indexRoutes: IRoute[] = [
    { path: FE_ROUTES.THANKYOU, component: ThankYou},
    { path: FE_ROUTES.REGISTER, component: Register},
    { path: FE_ROUTES.LOGIN, component: Login},
    { path: FE_ROUTES.MYACCOUNT, component: MyAccount},
    { path: FE_ROUTES.CHECKOUT, component: Checkout},
    { path: FE_ROUTES.CONTACTUS, component: Contact },
    { path: FE_ROUTES.ABOUTUS, component: AboutUs },
    { path: FE_ROUTES.FAQ, component: FAQ},
    { path: FE_ROUTES.PRICING, component: Pricing },
    { path: FE_ROUTES.DEMO, component: DemoHome },
    { path: FE_ROUTES.ROOT, component: Home }
];

export default indexRoutes;