import * as ACTIONS from '../../constants/actions/actions';
import {
  IDestination,
  IDuration,
  IPax,
  SetDepartureDate,
  SetDestination,
  SetDuration,
  SetDurationPickerVisibility,
  SetFlightDetailsVisibility, SetMonths,
  SetPax,
  SetPaxPickerVisibility,
  SetQuery, SetRedirectTo, SetTSM
} from "../../types/bookingStoreTypes/clientStoreTypes";

/**
 * set search query on input change
 * @param query
 */
export function setQuery(query: string): SetQuery {
  return {
    type: ACTIONS.SET_QUERY,
    payload: query
  }
}

/**
 * set search destination
 * @param destination
 */
export function setDestination(destination: IDestination | IDestination[] | null): SetDestination {
 return {
   type: ACTIONS.SET_DESTINATION,
   payload: destination
 }
}

/**
 * set departure date for search
 * @param departure
 */
export function setDepartureDate (departure: Date | null): SetDepartureDate {
  return {
    type: ACTIONS.SET_DATE,
    payload: departure
  }
}

/**
 * set number of days & nights in object.
 * @param duration
 */
export function setDuration(duration: IDuration): SetDuration {
  return {
    type: ACTIONS.SET_DURATION,
    payload: duration
  }
}

/**
 * set number of adults & children in object.
 * @param pax
 */
export function setPax(pax: IPax): SetPax {
  return {
    type: ACTIONS.SET_PAX,
    payload: pax
  }
}

/**
 * hide picker on outside click
 * @param isVisible
 */
export function setDurationPickerVisibility(isVisible: boolean): SetDurationPickerVisibility {
  return {
    type: ACTIONS.SET_DURATION_VISIBILITY,
    payload: isVisible
  }
}

/**
 * hide pax picker on outside click
 * @param isVisible
 */
export function setPaxPickerVisibility(isVisible: boolean): SetPaxPickerVisibility {
  return {
    type: ACTIONS.SET_PAX_VISIBILITY,
    payload: isVisible
  }
}

/**
 * show flight details modal/popup for different packages of same tour
 * @param isVisible
 */
export function setFlightDetailsVisibility(isVisible: boolean): SetFlightDetailsVisibility {
  return {
    type: ACTIONS.SET_FLIGHT_DETAILS_VISIBILITY,
    payload: isVisible
  }
}

/**
 * set minutes from GMT to client time zone (-timezoneOffset)
 * @param tsm
 */
export function setTSM(tsm: number): SetTSM {
  return {
    type: ACTIONS.SET_TSM,
    payload: tsm
  }
}

/**
 * set number of months/ installments
 * @param installments
 */
export const setMonths = (installments: number) :SetMonths => {
  return {
    type: ACTIONS.SET_MONTHS,
    payload: installments
  }
};

/**
 * redirect back to path initiated, after sign in. if null redirect to default.
 * @param redirect
 */
export const setRedirect = (redirect: string | null): SetRedirectTo => {
  return {
    type: ACTIONS.SET_REDIRECT,
    payload: redirect
  }
};