import React from 'react';
import {Col} from "react-bootstrap";
import cat1 from '../../assets/images/cat.jpg';
import {useDispatch, useSelector} from "react-redux";
import {setCategory, setDemoCategory} from "../../store/actions/itemActions";
import {AppState} from "../../store/reducers";

type CategoryProps = {
  category: string,
  img: string
}

const Category: React.FC<CategoryProps> = (props) => {
  const cat2: string | null = useSelector((state: AppState) => state.item.demoCategory);

  const dispatch = useDispatch();

  const {category, img} = props;

  const dispatchCategory = (category: string | null) => {
    dispatch(setDemoCategory(category !== 'all' ? category : null));
  };

  return (
    <Col xs={2} sm={2} md={2} lg={2}
         // className="cat-box cat-img text-center"
         className={`cat-box cat-img text-center ${(category === cat2 || (cat2 === null && category === 'all')) ? 'active' : ''} `}
         onClick={() => dispatchCategory(category)}>
      <img src={img !== '' ? img : cat1} alt={cat1}/>
      <p className='font-weight-bold mt-3 text-capitalize'>{category}</p>
    </Col>
  );
};

export default Category;