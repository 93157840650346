import React, {useEffect, useState} from 'react';
import Slider from "../components/Slider/Slider";
import SearchBar from "../components/SearchBar/SearchBar";
import CatMenu from "../components/CatMenu/CatMenu";
import DemoProducts from "../components/Product/DemoProducts";
import DemoCategories from "../components/Category/DemoCategories";
import PopUpModel from "../components/PopUpModel/PopUpModel";
import {useParams, useLocation} from "react-router-dom";
import {API_ROUTES} from "../routes/apiRoutes";
import axios from "axios";

const Home: React.FC = () => {
  const {title} = useParams();
// console.log(title);
  const [status, setStatus] = useState(0); //status codes returned by api.
  const sentStatusKey = 'sidST';
  const sentStatus = 'sent';

  function useUrlQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const updateSentStatus = () => {
    localStorage.setItem(sentStatusKey, sentStatus);
  };

  const query = useUrlQuery();
  let sid = query.get('sid');
// console.log(sid);

  useEffect(() => {
    // console.log(title);
    // console.log(sid);
    handleSubmit();
  }, []);

    const handleSubmit = async () => {
        // console.log(title);
        // console.log(sid);

        if (!sid) {
            return;
        }

        const status = await localStorage.getItem(sentStatusKey);
        if (status && status === sentStatus) {
            return;
        }

        const deal = {
            sc: sid,
            ac: 'stov',
        };

        axios
          .post(API_ROUTES.SID, deal)
          .then((res: any) => {
              setStatus(200);
              updateSentStatus();
          }).catch((err: any) => {

            if (!err.response) {
                setStatus(500);
                // setErrorMsg('Network Problem. Try again later.');
            }

            if (err.response &&
              (err.response.status === 400 || err.response.status === 403 || err.response.status === 500)) {
                setStatus(err.response.status);
                // setErrorMsg(err.response.data.msg);
                return;
            }

            setStatus(400);
        });

    };


    return (
    <React.Fragment>
      <div className='root green'>
        <PopUpModel/>
        <CatMenu/>
        <Slider/>
        <SearchBar/>
        <DemoCategories/>
        <DemoProducts/>
      </div>
    </React.Fragment>
  );
};

export default Home;