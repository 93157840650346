import {ClientActionTypes, ClientState, IDestination, IDuration, IPax, ISearch} from "../../types/bookingStoreTypes/clientStoreTypes";
import * as ACTIONS from '../../constants/actions/actions';

const initDuration: IDuration = {
  nights: 3,
  days: 4,
  isDefault: true
};

export const initPax: IPax = {
  adults: 2,
  children: 0,
  isDefault: true
};

const InitSearch: ISearch = {
  query: '',
  destination: null,
  departure: null,
  duration: initDuration,
  pax: initPax,
  TSM: 0,
  months: 36,
};

export const InitialClientState: ClientState = {
  search: InitSearch,
  isDurationPickerOn: false,
  isPaxPickerOn: false,
  isFlightDetailsVisible: false,
  redirectTo: null
};

export function clientReducer(state = InitialClientState, action: ClientActionTypes): ClientState {
  switch (action.type) {
    case ACTIONS.SET_QUERY: {
      return {
        ...state,
        search: {...state.search, query: action.payload, destination: null}
      };
    }
    case ACTIONS.SET_DESTINATION: {
      const destination: IDestination | IDestination[] | null = action.payload;

      let dest: IDestination | null = null;
      if (destination && !Array.isArray(destination)) {
        dest = {id: destination.id, name: destination.name, info: destination.info, type: destination.type};
      }

      return {
        ...state,
        // search: {...state.search, destination: action.payload}
        search: {...state.search, destination: dest}
      };
    }
    case ACTIONS.SET_DATE: {
      const offset = action.payload ? action.payload.getTimezoneOffset() : state.search.TSM;
      return {
        ...state,
        search: {...state.search, departure: action.payload, TSM: -offset}
      };
    }
    case ACTIONS.SET_DURATION: {
      return {
        ...state,
        search: {...state.search, duration: action.payload}
      };
    }
    case ACTIONS.SET_PAX:
      return {
        ...state,
        search: {...state.search, pax: action.payload}
      };
    case ACTIONS.SET_DURATION_VISIBILITY:
      return {
        ...state,
        isDurationPickerOn: action.payload,
      };
    case ACTIONS.SET_PAX_VISIBILITY:
      return {
        ...state,
        isPaxPickerOn: action.payload,
      };
    case ACTIONS.SET_FLIGHT_DETAILS_VISIBILITY:
      return {
        ...state,
        isFlightDetailsVisible: action.payload
      };
    case ACTIONS.SET_TSM:
      return {
        ...state,
        search: {...state.search, TSM: action.payload}
      };
    case ACTIONS.SET_MONTHS:
      return {
        ...state,
        search: {...state.search, months: action.payload}
      };
    case ACTIONS.SET_REDIRECT:
      return {
        ...state,
        redirectTo: action.payload
      };
    default:
      return state;
  }
}