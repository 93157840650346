import {applyMiddleware, compose, createStore} from "redux";
import {rootReducer} from "./reducers";
import thunkMiddleware from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";

/**
 * remove composeWithDevTools option before production
 * @param preloadedState
 */
export default function configureStore(preloadedState?: any) {
    // return createStore(rootReducer, preloadedState, compose(applyMiddleware(thunkMiddleware)));
    // return createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(thunkMiddleware)));

    let persistedState = {};
    const localStoreState = localStorage.getItem('rst'); //rx = redux Storage
    if (localStoreState) {
        persistedState = JSON.parse(localStoreState);
    }

    const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(thunkMiddleware))); //dev
    // const store = createStore(rootReducer, persistedState, compose(applyMiddleware(thunkMiddleware))); //production

    store.subscribe(() => {
        localStorage.setItem('rst', JSON.stringify(store.getState()));
    });
    return store;
}