import React, {useEffect, useState} from 'react';
import {Redirect, useLocation} from "react-router-dom";
import {Container, Col, Row, Image, Button, Card, Form} from "react-bootstrap";
// import BillingInfoPersonal from "../left/BillingInfoPersonal";
// import BillingInfoRegister from "../left/BillingInfoRegister";
// import CardAddress from "../left/CardAddress";
// import UploadPassports from "../../../../components/details/UploadPassports";
// import EnterPassports from "../../../../components/details/EnterPassports";
// import UploadLater from "../../../../components/details/UploadLater";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import BookingFeedback from "./BookingFeedback";
// import {getLoadingSpinner} from "../../../utils/loading/loading";
// import {useLazyQuery, useQuery} from "@apollo/react-hooks";
// import {VALIDATE_BOOKING} from "../../../graphql/queries/BookingQuery";

export const UPLOAD_TYPES = {
    UPLOAD: 'upload',
    ENTER: 'enter',
    LATER: 'later',
};

const ThankYou: React.FC = () => {
    const useUrlQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useUrlQuery();
    let orderID = query.get('booking_id'); // get order_id returned from IPG.
    orderID = orderID ? orderID : '';

    const [isRedirect, setIsRedirect] = useState(false);

    // const[validateBooking, {loading, data, error, called}] = useLazyQuery(VALIDATE_BOOKING, {variables: {
    //     orderID: parseInt(orderID)
    //   }});

    // useEffect(() => {
    //   setTimeout(() => {
    //     validateBooking();
    //   }, 3000)
    // },[]);
    //
    //
    // if (loading || !called) {
    //   return (
    //     <Container fluid={false} className='feedback-loading text-center'>
    //       <div className='mt-5 feedback-loading-container'>
    //         {getLoadingSpinner({animation: "border", variant: "warning"})}
    //         <span className='loading-payment'>Loading... Please wait! </span>
    //       </div>
    //     </Container>
    //
    //   );
    // }

    // if (error || (data && !data.validateBooking)) {
    //   return (
    //     <Container fluid={false} className='feedback-loading text-center'>
    //       <p className='mt-5 text-danger error-payment'>
    //         Problem loading confirmation!<br/>
    //         contact <a className='text-primary' href="mailto:nfo@toursharbor.com">info@toursharbor.com </a>
    //         immediately for assistance.
    //       </p>
    //     </Container>
    //
    //   );
    // }

    return (
        // <div>Thank you for your order #{orderID}.</div>
        <React.Fragment>
            {isRedirect && <Redirect to='/'/>}

            <div className='feedback-top'/>
            <Container fluid={false} className='feedback-top1'>
                <BookingFeedback
                    // bookingID={orderID}
                    bookingID={'123456'}
                    voucher={'voucher'}
                    remarks={'remarks'}
                    // voucher={data.validateBooking.voucher}
                    // remarks={data.validateBooking.remarks}
                />

                {/*<PersonalDetails orderID={data.validateBooking.orderID}*/}
                {/*                 uploadDeadline={addDaysToDate(getDateTime(data.validateBooking.createdAt), 2)}*/}
                {/*                 pax={{...data.validateBooking.pax, isDefault: false}}/>*/}

                <Row className="d-flex flex-row-reverse mt-lg-4">
                    <Col xs={12} sm={12} md={3} lg={3} className='mt-5 mb-5'>
                        <div className="continue-shopping-btn mt-lg-5">
                            <Button variant='outline-secondary' className='shopping-btn'
                                    onClick={() => setIsRedirect(true)}>
                                Continue Shopping ></Button>
                        </div>
                    </Col>
                </Row>

            </Container>
        </React.Fragment>
    );
};

export default ThankYou;