import React from 'react';
import './App.scss';
import {createBrowserHistory} from "history";
import {Router} from "react-router";
import PageLayout from "./layout/PageLayout";
import { Provider } from "react-redux";
import configureStore from "./store";
import {rootReducer} from "./store/reducers";
import {createStore} from "redux";

function App() {
  const hist = createBrowserHistory();
  const store = configureStore();

    // const store = createStore(rootReducer);

  return (
      <Provider store={store}>
          <Router history={hist}>
              <PageLayout/>
          </Router>
      </Provider>

  );
}

export default App;
