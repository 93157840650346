import * as ACTIONS from '../../constants/actions/booking';
import {
  CREDIT_PAYMENT,
  FULL_PAYMENT,
  ICardInfo, InitBookingData, NewUser, ResetBooking,
  SetBillingInfo,
  SetCardInfo, SetInitBookingData,
  SetIsAgreed,
  SetIsNewsletterPreferred,
  SetIsSameBillingAddress, SetIsValidated, SetNewUser, SetOrderID,
  SetPaymentMode,
  SetPersonalDetails, SetTotal
} from "../../types/bookingStoreTypes/bookingStoreTypes";
import {BillingInfo} from "../../types/bookingStoreTypes/bookingProps";

/**
 * set personal details from the form
 * @param personalDetails
 */
export const setPersonalDetails = (personalDetails: BillingInfo) : SetPersonalDetails => {
  return {
    type: ACTIONS.SET_PERSONAL_DETAILS,
    payload: personalDetails
  }
};

/**
 * set credentials for new user
 * @param newUser
 */
export const setUserDetails = (newUser: NewUser | null) : SetNewUser => {
  return {
    type: ACTIONS.SET_NEW_USER,
    payload: newUser
  }
};

/**
 * set billing address type to same or different
 * @param isSameAddress - true if same as personal details address
 */
export const setIsSameBillingAddress = (isSameAddress: boolean) : SetIsSameBillingAddress => {
  return {
    type: ACTIONS.SET_BILLING_ADDRESS_TYPE,
    payload: isSameAddress
  }
};

/**
 * set billing info if isSameBillingAddress is false
 * @param billingInfo
 */
export const setBillingInfo = (billingInfo: BillingInfo | null) : SetBillingInfo => {
  return {
    type: ACTIONS.SET_BILLING_INFO,
    payload: billingInfo
  }
};

/**
 * set payment mode to credit card full payment or installments.
 * @param paymentMode
 */
export const setPamentMode = (paymentMode: typeof CREDIT_PAYMENT | typeof FULL_PAYMENT) : SetPaymentMode => {
  return {
    type: ACTIONS.SET_PAYMENT_MODE,
    payload: paymentMode
  }
};

/**
 * set card issuing bank and country of bank if installments
 * @param cardInfo
 */
export const setCardInfo = (cardInfo: ICardInfo | null) : SetCardInfo => {
  return {
    type: ACTIONS.SET_CARD_INFO,
    payload: cardInfo
  }
};

/**
 * set true if receiving newsletter, offers preferred.
 * @param isPreferred
 */
export const setIsNewsletterPreferred = (isPreferred: boolean) : SetIsNewsletterPreferred => {
  return {
    type: ACTIONS.SET_NEWSLETTER_PREFERENCE,
    payload: isPreferred
  }
};

/**
 * set true if terms & conditions of app is agreed by the user.
 * @param agreed
 */
export const setIsAgreed = (agreed: boolean) : SetIsAgreed => {
  return {
    type: ACTIONS.SET_AGREEMENT,
    payload: agreed
  }
};

/**
 * set all form input validated, to show form input default errors.
 * @param isValidated
 */
export const setIsValidated = (isValidated: boolean) : SetIsValidated => {
  return {
    type: ACTIONS.SET_IS_VALIDATED,
    payload: isValidated
  }
};

/**
 * set orderID from init booking mutations
 * @param isValidated
 */
export const setOrderID = (orderID: number | null) : SetOrderID => {
  return {
    type: ACTIONS.SET_ORDER_ID,
    payload: orderID
  }
};

/**
 * set order total from mutation result
 * @param total
 */
export const setTotal = (total: number | null) : SetTotal => {
  return {
    type: ACTIONS.SET_TOTAL,
    payload: total
  }
};

/**
 * set data received from init Booking mutation (orderID, total)
 * @param initBookingData
 */
export const setInitBookingData = (initBookingData: InitBookingData) : SetInitBookingData => {
  return {
    type: ACTIONS.SET_INIT_BOOKING_DATA,
    payload: initBookingData
  }
};

/**
 * reset certain variables on paynow page reload. refer to reducer for changes.
 */
export const resetBooking = () : ResetBooking => {
  return {
    type: ACTIONS.RESET_BOOKING
  }
};

