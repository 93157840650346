import React from 'react';

type TbItemPhotoProps = {
    itemPhoto: string
}

const TbItemPhoto: React.FC<TbItemPhotoProps> = (props) => {
    const {itemPhoto} = props;

    return (
        <div className="tb-item-photo">
            <img src={itemPhoto} alt={''}/>
        </div>
    )
};

export default TbItemPhoto;