import React from 'react';
import {Button, Col, Row} from "react-bootstrap";

const PaymentMethods: React.FC = () => {
    return (
        <div className="payment-method-main-text">
            <h5>Payment Methods</h5>
            <Row className="flex-fill justify-content-around ">
                <Col xs={11} sm={5} md={5} lg={5} className="credit-card-bg mt-2">
                    <i className="far fa-credit-card" />
                   <div>
                       <label className="payment-label-name">Credit/Debit Card</label>
                   </div>
                </Col>
                <Col xs={11} sm={5} md={5} lg={5} className="credit-card-bg mt-2">
                    <i className="far fa-money-bill-alt" />
                    <div>
                        <label className="payment-label-name">Cash on Delivery</label>
                    </div>
                </Col>
            </Row>
            <div className="order-btn justify-content-center">
                <a href='https://optin.spencehub.com/'>
                    <Button>Order</Button>
                </a>
            </div>
        </div>
    )
};

export default PaymentMethods;