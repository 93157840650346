import React from 'react';
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import thumbsUp from "../../assets/images/feedback/hand.png";

type BookingFeedbackProps = {
  bookingID: string
  voucher?: string | null
  remarks: string | null
}

const BookingFeedback: React.FC<BookingFeedbackProps> = (props) => {
  const {bookingID, remarks, voucher} = props;

  const getVoucherDownload = (voucher: string) => {
    return(
      <a href={voucher} target='_blank' className='link-no-styles'>
        <Button variant="outline-secondary" className='download-voucher mt-2 mb-2' size='sm'>
          <i className='feather icon-download'/>
          Download Invoice
        </Button>
      </a>
    )
  };

  return (
    <Row>
      <Col lg={{span: 9}} xs={{span: 10, offset: 1}} md={{span: 10, offset: 1}} className='mt-5 pb-3 feedback pt-4 pb-5'>
        <Image src={thumbsUp} className='thumbs-up'/>
        <p className='thank-you'>Thank you for Order</p>
        <p className='booking-id'>Order ID: {bookingID}</p>
        <p className='email-copy'>Copy of your order details {remarks ? 'has been' : 'will be'} emailed to you.</p>
        {/*{voucher && getVoucherDownload(voucher)}*/}

      </Col>
    </Row>
  );
};

export default BookingFeedback;