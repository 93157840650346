import React from 'react';

function Contact() {
    return (
        <div className="text-center mt-5 mb-5">
            <h1>Contact us</h1>
        </div>
    )
}

export default Contact;