import React from 'react';
import {Col, Container, Form, FormControl} from "react-bootstrap";

function SearchBar() {
    return(
        <Container>
            <Form>
                <Col className="search-box align-content-center">
                    <FormControl type="text" placeholder="Search..."/>
                   <div className='search-icon '>
                       <i className="feather icon-search"/>
                   </div>
                </Col>
            </Form>
        </Container>
    )
}

export default SearchBar;