import React, {useEffect, useState} from 'react';
import {Col, Form, InputGroup} from "react-bootstrap";
import Select from "react-select";
import {countries, findCountryByValue} from "../../constants/values";
import {customDropdownStylesNoColor} from "../../constants/dropdownStyles";
// @ts-ignore
import ReactCountryFlag from "react-country-flag";
import {useDispatch, useSelector} from "react-redux";
import {BillingInfo} from "../../types/bookingStoreTypes/bookingProps";
import {AppState} from "../../store/reducers";
import {setPersonalDetails} from "../../store/actions/bookingActions";
import {BILLING_ADDRESS_PERSONAL} from "../../constants/values";

type BillingInfoPersonalProps = {
  isValidated: boolean,
  lastBillingInfo?: BillingInfo | null
}

const BillingInfoPersonal: React.FC<BillingInfoPersonalProps> = (props) => {
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const {isValidated, lastBillingInfo} = props;

  const personalInfo: BillingInfo | null = useSelector((state: AppState) => state.booking.personalDetails);
  const countryCode: string = useSelector((state: AppState) => state.item.geo.countryCode);
  const callingCode: string = useSelector((state: AppState) => state.item.geo.callingCode);

  const dispatch = useDispatch();

  // const phonePrefix = '+94';

  let personalDetails: BillingInfo = lastBillingInfo ? lastBillingInfo : {
    fullName: null,
    address: null,
    city: null,
    postcode: null,
    country: null,
    tel: null,
    type: BILLING_ADDRESS_PERSONAL,
  };

  const [fullName, setFullName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [postcode, setPostcode] = useState<string>('');
  const [tel, setTel] = useState<string>('');
  const [country, setCountry] = useState<string>('');

  useEffect(() => {
    personalDetails = personalInfo ? personalInfo : personalDetails;

    (fullName !== personalDetails.fullName && personalDetails.fullName) && setFullName(personalDetails.fullName);
    (address !== personalDetails.address && personalDetails.address) && setAddress(personalDetails.address);
    (city !== personalDetails.city && personalDetails.city) && setCity(personalDetails.city);
    (postcode !== personalDetails.postcode && personalDetails.postcode) && setPostcode(personalDetails.postcode);
    (tel !== personalDetails.tel && personalDetails.tel) && setTel(personalDetails.tel);
    if (country !== personalDetails.country && personalDetails.country) {
      setCountry(personalDetails.country);
      setIsCountrySelected(true)
    }
  });

  useEffect(() => {
    console.log(lastBillingInfo);
    lastBillingInfo && dispatch(setPersonalDetails(lastBillingInfo))
  }, [lastBillingInfo]);

  const handleOnFullNameChange = (fullName: string) => {
    dispatch(setPersonalDetails({...personalDetails, fullName}));
  };

  const handleOnAddressChange = (address: string) => {
    dispatch(setPersonalDetails({...personalDetails, address}));
  };

  const handleOnCityChange = (city: string) => {
    dispatch(setPersonalDetails({...personalDetails, city}));
  };

  const handleOnPostcodeChange = (postcode: string) => {
    dispatch(setPersonalDetails({...personalDetails, postcode}));
  };

  const handleOnTelChange = (tel: string) => {
    dispatch(setPersonalDetails({...personalDetails, tel: callingCode + tel}));
  };

  const handleOnCountrySelected = (option: any) => {
    setIsCountrySelected(true);
    dispatch(setPersonalDetails({...personalDetails, country: option.value}));
  };

  return (
    <Form.Row>
      <Form.Group as={Col} md="12" controlId="validationCustomFullName">
        <Form.Label>Full Name*</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Your Full Name"
          defaultValue={fullName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnFullNameChange(e.target.value)}
        />
        {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
        <Form.Control.Feedback type="invalid">
          Please provide your full name
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="12" controlId="validationCustomAddress">
        <Form.Label>Address*</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Street Address"
          defaultValue={address}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnAddressChange(e.target.value)}
        />
        {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
        <Form.Control.Feedback type="invalid">
          Please provide your address
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="4" controlId="validationCustomCity">
        <Form.Label>City / suburb*</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="City / suburb"
          defaultValue={city}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnCityChange(e.target.value)}
        />
        {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
        <Form.Control.Feedback type="invalid">
          Please provide city / suburb
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="4" controlId="validationCustomPostCode">
        <Form.Label>Postal Code*</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Postal Code"
          defaultValue={postcode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnPostcodeChange(e.target.value)}
        />
        {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
        <Form.Control.Feedback type="invalid">
          Please provide post code
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="4" controlId="validationCustomCountry">
        <Form.Label>Country*</Form.Label>
        <Select
          styles={customDropdownStylesNoColor}
          className="basic-single"
          classNamePrefix="select"
          // isClearable={true}
          value={findCountryByValue(country)}
          name="color"
          onChange={(option) => option && handleOnCountrySelected(option)}
          options={countries}
        />
        {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
        <Form.Control.Feedback
          className={isValidated && !isCountrySelected ? 'dis-block' : ''}
          type="invalid">
          Please provide country
        </Form.Control.Feedback>
      </Form.Group>


      <Form.Group as={Col} md="12" controlId="validationCustomPhone">
        <Form.Label>Contact Number*</Form.Label>

        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              <div className='flag'>
                {/*<ReactCountryFlag countryCode="LK" svg*/}
                <ReactCountryFlag countryCode={countryCode} svg
                                  style={{
                                    fontSize: '1.2em',
                                    lineHeight: '1em',
                                    width: '2.5em',
                                    height: '.8em',
                                  }}
                                  aria-label="Sri Lanka"/>
              </div>
              {callingCode}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            required
            type="text"
            placeholder=""
            className='prepended-control'
            defaultValue={tel}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnTelChange(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide phone number
          </Form.Control.Feedback>
        </InputGroup>

      </Form.Group>

    </Form.Row>
  );
};

export default BillingInfoPersonal;