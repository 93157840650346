import React from 'react';
import {Container, Dropdown, Row, Nav, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {getCompanyWebsite} from "../../util/common";

const CatMenu: React.FC = () => {
    const countryName: string = useSelector((state: AppState) => state.item.geo.countryName);

    return (
    <Container fluid={false} className="mt-4 mb-3 dropdown-btn">
      <Row>
        <div className="nav-item-text">
          <Nav>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Categories
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Cat 1</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Cat 2</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Cat 3</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Nav.Item>
              <Nav.Link href="/">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href={getCompanyWebsite(countryName) + "/about"} target='_blank'>About us</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/faq">FAQ</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              {/*<Link to="/pricing"><Button variant='outline-danger'>Pricing</Button></Link>*/}
              <Nav.Link href={'https://www.softvessel.com/services/e-commerce-website/pricing'}
                        target='_blank' className='pt-0'>
                <Button variant='outline-danger'>Pricing</Button>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href={getCompanyWebsite(countryName) + "/contact"} target='_blank'>Contact Us</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </Row>
    </Container>
  );
};

export default CatMenu;