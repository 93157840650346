import React, {useEffect, useState} from 'react';
import {Navbar, Nav, Button, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {getCurrencyLabel, getPhoneNumber} from "../../util/common";

function TopHeader() {
    const countryName: string = useSelector((state: AppState) => state.item.geo.countryName);
    const [phone, setPhone] = useState<string>(getPhoneNumber(countryName));

    useEffect(() => {
        setPhone(getPhoneNumber(countryName));
    }, [countryName]);

    return (
        <div className="top-header-bg">
            <Navbar expand="lg">
                <i className="feather icon-phone mt-0 mr-0"/><Nav.Link>{phone}</Nav.Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        <Row>
                            <i className="fas fa-truck"/><Nav.Link href="#delivery">Delivery
                            Areas</Nav.Link>
                        </Row>
                        <Row>
                            <i className="feather icon-user"> </i> <Nav.Link href="#myaccount">My Account</Nav.Link>
                        </Row>
                        <div className="header-register-btn">
                            <Link to="#register">
                                <Button>Register</Button>
                            </Link>
                        </div>
                        <div className="header-login-btn">
                            <Link to="#login">Login</Link>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className="divider"/>
        </div>
    )
}

export default TopHeader;