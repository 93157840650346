import React, {useEffect, useState} from 'react';
import {ButtonGroup, Col, Container, Row, ToggleButton} from "react-bootstrap";
import {useSelector} from "react-redux";
import {AppState} from "../store/reducers";
import {getCurrencyLabel, getPricing} from "../util/common";

const Pricing: React.FC = () => {
    const [isAnnualPlan, setIsAnnualPlan] = useState(true);

    const currencyCode: string = useSelector((state: AppState) => state.item.geo.currency);
    const [pricing, setPricing] = useState<any>(getPricing(currencyCode, isAnnualPlan));


    useEffect(() => {
        setPricing(getPricing(currencyCode, isAnnualPlan));
    }, [currencyCode, isAnnualPlan]);

    return (
        <React.Fragment>
            <div className='pricing-title'>
                <h1>Pricing Plans</h1>
            </div>
            <Container>
                <Row>
                    <Col xs={12} className='mt-3 mb-5 text-center'>
                        <ButtonGroup toggle className='plan-toggle'>
                            <ToggleButton
                              type="checkbox"
                              variant={isAnnualPlan ? 'primary': 'outline-primary'}
                              checked={isAnnualPlan}
                              value="Annually"
                              name='plan'
                              className='mr-0'
                              onChange={() => setIsAnnualPlan(true)}
                            >
                                Annually
                            </ToggleButton>
                            <ToggleButton
                              type="checkbox"
                              variant={!isAnnualPlan ? 'primary': 'outline-primary'}
                              checked={!isAnnualPlan}
                              value="Monthly"
                              name='plan'
                              onChange={() => setIsAnnualPlan(false)}
                            >
                                Monthly
                            </ToggleButton>

                        </ButtonGroup>
                    </Col>
                </Row>
            </Container>

            <div className="demo pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="pricingTable">
                                <div className="pricing-content">
                                    <div className="pricingTable-header">
                                        <h3 className="title">STARTER</h3>
                                    </div>
                                    <div className="price-value top-pricing">
                                        <label className="amount">
                                            {pricing.p1}
                                        </label>
                                        <label className="duration"> /month</label>
                                    </div>
                                    <ul className="content-list">
                                        <li>
                                            <i className="feather icon-check"/>
                                            Web store
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Delivery areas
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Products panel
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Cash on delivery
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Email order notify
                                        </li>
                                        <li>
                                            <i className="feather icon-x"/>
                                            SMS order notify
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Product categories
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Own domain host
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Upload order pharmacy only*
                                        </li>
                                        <li>
                                            <i className="feather icon-x"/>
                                            Online checkout<br/>
                                            (payment gateway)
                                        </li>
                                        <li>
                                            <i className="feather icon-x"/>
                                            Pick-up in Store
                                        </li>
                                        <li>
                                            <i className="feather icon-x"/>
                                            Product subscription
                                        </li>
                                        <li>
                                            <i className="feather icon-x"/>
                                            Stock management
                                        </li>
                                        <li>
                                            <i className="feather icon-x"/>
                                            CRM
                                        </li>
                                        <li>
                                            <i className="feather icon-x"/>
                                            Loyalty rewards
                                        </li>
                                    </ul>
                                    {/*<div className="price-value">*/}
                                    {/*    <span className="amount">{pricing.p1}</span>*/}
                                    {/*    <span className="duration">per month</span>*/}
                                    {/*</div>*/}
                                    <div className="pricingTable-signup">
                                        <a href="http://www.softvessel.com/contact" target='_blank'>Contact Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="pricingTable blue">
                                <div className="pricing-content">
                                    <div className="pricingTable-header">
                                        <h3 className="title">PREMIUM</h3>
                                    </div>
                                    <div className="price-value top-pricing">
                                        <label className="amount">
                                            {pricing.p2}
                                        </label>
                                        <label className="duration"> /month</label>
                                    </div>
                                    <ul className="content-list">
                                        <li>
                                            <i className="feather icon-check"/>
                                            Web store
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Delivery areas
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Products panel
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Cash on delivery
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Email order notify
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            SMS order notify
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Product categories
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Own domain host
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Upload order pharmacy only*
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Online checkout<br/>
                                            (payment gateway)
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Pick-up in Store
                                        </li>
                                        <li>
                                            <i className="feather icon-x"/>
                                            Product subscription
                                        </li>
                                        <li>
                                            <i className="feather icon-x"/>
                                            Stock management
                                        </li>
                                        <li>
                                            <i className="feather icon-x"/>
                                            CRM
                                        </li>
                                        <li>
                                            <i className="feather icon-x"/>
                                            Loyalty rewards
                                        </li>
                                    </ul>
                                    {/*<div className="price-value">*/}
                                    {/*    <span className="amount">{pricing.p2}</span>*/}
                                    {/*</div>*/}
                                    <div className="pricingTable-signup">
                                        <a href="http://www.softvessel.com/contact" target='_blank'>Contact Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="pricingTable magenta">
                                <div className="pricing-content">
                                    <div className="pricingTable-header">
                                        <h3 className="title">ULTIMATE</h3>
                                    </div>
                                    <div className="price-value top-pricing">
                                        <label className="amount">
                                            {pricing.p3}
                                        </label>
                                        <label className="duration"> /month</label>
                                    </div>
                                    <ul className="content-list">
                                        <li>
                                            <i className="feather icon-check"/>
                                            Web store
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Delivery areas
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Products panel
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Cash on delivery
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Email order notify
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            SMS order notify
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Product categories
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Own domain host
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Upload order pharmacy only*
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Online checkout<br/>
                                            (payment gateway)
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Pick-up in Store
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Product subscription
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Stock management
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            CRM
                                        </li>
                                        <li>
                                            <i className="feather icon-check"/>
                                            Loyalty rewards
                                        </li>
                                    </ul>
                                    {/*<div className="price-value">*/}
                                    {/*    <span className="amount">{pricing.p3}</span>*/}
                                    {/*</div>*/}
                                    <div className="pricingTable-signup">
                                        <a href="http://www.softvessel.com/contact" target='_blank'>Contact Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="conditions-text">
                <p>*Term & Conditions apply</p>
            </div>
            <div className='discount-bar'>
                <Container fluid={false} >
                    <Row>
                        <Col md={9}>
                            <p className='discount-title'>Get a discount coupon to start your web store.</p>
                        </Col>
                        <Col md={3} className='text-center'>
                            <a href='https://optin.spencehub.com/nextgen-web-store-that-generate-predictable-income-sri-lanka?sid=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IiIsIlNDIjoic3RvcmUiLCJERCI6IjVlZDBlMTQyOTM4MDllMmNlZjQyMTc3NyIsIlNUIjoxNTkwNzQ3NDU4NzMyLCJpYXQiOjE1OTA3NDc0NTh9.h3gInSihw_HpHALpT52etV2pbgYy9YxfyWx8f1xL5GY'
                               className='discount-link' target='_blank'>
                                Click here
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default Pricing;