import React from 'react';
import {Form} from "react-bootstrap";

const DeliveryInstructions: React.FC = () => {
    return (
        <div className='mt-3 mb-3'>
            <p className='address-title'>Add Delivery Instructions (Optional)</p>
            <Form.Control as="textarea" rows="3" />
        </div>
    )
};

export default DeliveryInstructions;