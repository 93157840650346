import * as ACTIONS from '../../constants/actions/booking';
import {BookingState, BookingStoreTypes, CREDIT_PAYMENT} from "../../types/bookingStoreTypes/bookingStoreTypes";
import {initPax} from "./clientReducer";

const bookingInitialState: BookingState = {
  pax: initPax,
  personalDetails: null,
  newUer: null,
  isSameBillingAddress: true,
  billingInfo: null,
  paymentMode: CREDIT_PAYMENT,
  cardInfo: null,
  newsletter: false,
  agreement: false,
  isValidated: false,
  discountCode: null,
  discount: 0,
  orderID: null,
  total: null
};

export const bookingReducer = (state = bookingInitialState, action: BookingStoreTypes): BookingState => {
  switch (action.type) {
    case ACTIONS.SET_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetails: action.payload
      };
    case ACTIONS.SET_NEW_USER:
      return {
        ...state,
        newUer: action.payload
      };
    case ACTIONS.SET_BILLING_ADDRESS_TYPE:
      return {
        ...state,
        isSameBillingAddress: action.payload
      };
    case ACTIONS.SET_BILLING_INFO:
      return {
        ...state,
        billingInfo: action.payload
      };
    case ACTIONS.SET_PAYMENT_MODE:
      return {
        ...state,
        paymentMode: action.payload
      };
    case ACTIONS.SET_CARD_INFO:
      return {
        ...state,
        cardInfo: action.payload
      };
    case ACTIONS.SET_NEWSLETTER_PREFERENCE:
      return {
        ...state,
        newsletter: action.payload
      };
    case ACTIONS.SET_AGREEMENT:
      return {
        ...state,
        agreement: action.payload
      };
    case ACTIONS.SET_IS_VALIDATED:
      return {
        ...state,
        isValidated: action.payload
      };
    case ACTIONS.SET_ORDER_ID:
      return {
        ...state,
        orderID: action.payload
      };
    case ACTIONS.SET_TOTAL:
      return {
        ...state,
        total: action.payload
      };
    case ACTIONS.SET_INIT_BOOKING_DATA:
      return {
        ...state,
        orderID: action.payload.orderID,
        total: action.payload.total
      };
    case ACTIONS.RESET_BOOKING:
      return {
        ...state,
        total: null,
        orderID: null
      };
    default:
      return state;
  }
};