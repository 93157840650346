import {
    AddItem,
    ChangeItem,
    Geo,
    Item,
    RemoveItem,
    SetCategory,
    SetDemoCategory,
    SetGeo,
    SetWelcomeCount
} from "../../types/itemStoreTypes";
import {ADD_ITEM, CHANGE_ITEM, REMOVE_ITEM, SET_CATEGORY, SET_DEMO_CATEGORY} from "../../constants/actions/ItemActions";
import {SET_GEO, SET_WELCOME_COUNT} from "../../constants/actions/actions";

export function addItem(newItem: Item): AddItem {
    return {
        type: ADD_ITEM,
        payload: newItem
    }
}

export function removeItem(id: number): RemoveItem {
    return {
        type: REMOVE_ITEM,
        payload: id
    }
}

export function changeItem(item: Item): ChangeItem {
    return {
        type: CHANGE_ITEM,
        payload: item
    }
}

export function setCategory(category: string | null): SetCategory {
    return {
        type: SET_CATEGORY,
        payload: category
    }
}

/**
 * set category for demo page
 * @param category
 */
export function setDemoCategory(category: string | null): SetDemoCategory {
    return {
        type: SET_DEMO_CATEGORY,
        payload: category
    }
}

/**
 * increment welcome count (home visits) to show or not to show popup banner.
 */
export const setWelcomeCount = () :SetWelcomeCount => {
    return {
        type: SET_WELCOME_COUNT
    }
};

/**
 * set visitor geo data
 * @param geo
 */
export const setGeo = (geo: Geo) :SetGeo => {
    return {
        type: SET_GEO,
        payload: geo
    }
};