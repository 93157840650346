import React, {Component} from "react";
import {Container, Col, Row} from "react-bootstrap";
import softvessel from '../assets/images/softvessel logo.png';
import {useSelector} from "react-redux";
import {AppState} from "../store/reducers";
import {COMPANY_WEBSITE, COUNTRY_AU} from "../constants/values";
import {getCompanyWebsite} from "../util/common";

const Footer: React.FC = () => {
  const countryName: string = useSelector((state: AppState) => state.item.geo.countryName);

  const getAddressAu = () => {
      return (
        <p>
            SoftVessel<br/>
            Sydney, <br/>
            Australia<br/>
            +61 470 412 956<br/>
            Copyright © 2020
        </p>
      )
  };

  const getAddressROW = () => {
      return (
        <p>
            SoftVessel (Pvt) Ltd.<br/>
            60 Cotta Road, Colombo 8, <br/>
            Sri Lanka <br/>
            +94 779 510 260<br/>
            +94 112 680 360<br/>
            Copyright © 2020
        </p>
      )
  };

  return (
    <Container fluid={true} className="footer">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul className="nav justify-content-center footer-navlink">
            <li className="nav-item">
              <a className="nav-link" href="/">HOME</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={getCompanyWebsite(countryName) + "/about"} target='_blank'>ABOUT US</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/faq">FAQ</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/pricing">PRICING</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={getCompanyWebsite(countryName) + "/contact"} target='_blank'>CONTACT US</a>
            </li>
          </ul>
        </Col>
      </Row>
      <hr/>
      <Row>
        <Col lg={12}>
          <div className='softvessel-logo'>
            <img src={softvessel} alt='softvessel (pvt) ltd'/>
          </div>
          <div className='footer-address'>
              {countryName === COUNTRY_AU && getAddressAu()}
              {countryName !== COUNTRY_AU && getAddressROW()}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className='text-center'>
          <a href='https://www.facebook.com/spencehub/' target='_blank'>
            <i className='feather icon-facebook'/>
          </a>
          <i className='feather icon-twitter'/>
          <i className='feather icon-instagram'/>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;