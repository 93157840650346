import React, {useEffect, useState} from 'react';
import {ItemsListRow} from "../../types/table/TableTypes";
import TableToolKit from "../TableNext/TableToolKit";
import {listItemsColumns} from "../../constants/TableColumns";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import GuestUser from "./GuestUser";
import BillingInfo from "./BillingInfo";
import PaymentMethods from "./PaymentMethods";
import EditableQty from "./cartRow/EditableQty";
import TbItemPhoto from "./cartRow/TbItemPhoto";
import {Item} from "../../types/itemStoreTypes";
import {useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import NumberFormat from 'react-number-format';
import {smallCentsWithPrefix} from "../../util/uiComponents";
import RemoveIcon from "./cartRow/RemoveIcon";
import discount from '../../assets/images/discount.png';
import {Redirect} from "react-router";
import img from "../../assets/images/ezgif.com-webp-to-jpg (1).jpg";
import DeliveryInstructions from "./DeliveryInstructions";
import {getCurrencyLabel} from "../../util/common";
import CatMenu from "../CatMenu/CatMenu";

const Checkout: React.FC = () => {
  const [isRedirect, setIsRedirect] = useState(false);

  const currencyCode: string = useSelector((state: AppState) => state.item.geo.currency);
  const [currency, setCurrency] = useState<string>(getCurrencyLabel(currencyCode));

  useEffect(() => {
    setCurrency(getCurrencyLabel(currencyCode));
  }, [currencyCode]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const items: Item[] = useSelector((state: AppState) => state.item.items);

  const listRows = () => {
    return items.map((item: Item, index: number) => {
      const itemRow: ItemsListRow = {
        key: index + 1,
        name: item.name,
        itemPhoto: <TbItemPhoto itemPhoto={item.itemPhoto}/>,
        // qty: <EditableQty qty={item.qty ? item.qty : 1} />,
        qty: <EditableQty item={item}/>,
        unitPrice: <NumberFormat value={item.price} thousandSeparator={true} displayType='text'
                                 prefix={currency + ' '}
                                 decimalScale={2} fixedDecimalScale={true}
                                 renderText={smallCentsWithPrefix}/>,
        amount: <NumberFormat value={item.qty ? item.qty * item.price : item.price} thousandSeparator={true}
                              displayType='text'
                              prefix={currency + ' '}
                              decimalScale={2} fixedDecimalScale={true}
                              renderText={smallCentsWithPrefix}/>,
        removeIcon: <RemoveIcon item={item}/>
        // deliveryCost: 0,
        // total: 0
      };
      return itemRow;
    });
  };

  const totalPrice = items.reduce((total: number, b: Item) => total + (b.price * b.qty), 0);

  const getTable = () => {
    const rows = listRows();

    return <TableToolKit columns={listItemsColumns}
                         tableData={rows} keyField={'key'}
                         search={''}
                         tableClasses='item-table'
    />;
  };

  return (
    <React.Fragment>

      <CatMenu/>

      <Container fluid={false}>
        {isRedirect && <Redirect to='/'/>}

        <Row>
          <Col xs={12} className="checkout-text mt-5">
            <Row>
              <Col md={9} lg={10}>
                <div>
                  <h1>Checkout Page</h1>
                </div>
              </Col>
              <Col md={3} lg={2}>
                <div className="continue-shopping-btn">
                  <Button variant='outline-secondary' className='shopping-btn' onClick={() => setIsRedirect(true)}>
                    <i className='feather icon-chevron-left'/> Continue Shopping</Button>
                </div>
              </Col>
            </Row>
            <Card>
              <div className="shopping-cart-text">
                <h5>Shopping Cart</h5>
              </div>
              <Card.Body className="pt-0">
                {getTable()}
              </Card.Body>
              <Row>
                <Col xs={6} sm={6} md={8} lg={8}>
                  <label className="delivery-charge-text">Delivery Charge</label>
                </Col>
                <Col xs={6} sm={6} md={4} lg={4}>
                  <label className="delivery-charge"><NumberFormat value={0} prefix={currency + ' '} displayType={'text'}
                                                                   thousandSeparator={true} decimalScale={2}
                                                                   fixedDecimalScale={true}/></label>
                </Col>
              </Row>
              <div className="hr"/>
              <Row>
                <Col xs={6} sm={6} md={8} lg={8}>
                  <label className="delivery-charge-text">Est.Total</label>
                </Col>
                <Col xs={6} sm={6} md={4} lg={4}>
                  <label className="total-price"><NumberFormat value={totalPrice} prefix={currency + ' '} displayType={'text'}
                                                               thousandSeparator={true} decimalScale={2}
                                                               fixedDecimalScale={true}/></label>
                  {/*<label className="total-price">{totalPrice}</label>*/}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>


        <Row className='mt-4 mb-5 payment-container'>
          {/*<Row>*/}
          <Col lg={5} xs={12}>
            <div className="contact-banner checkout-banner">
              <a
                href='https://optin.spencehub.com/nextgen-web-store-that-generate-predictable-income-sri-lanka?sid=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IiIsIlNDIjoic3RvcmUiLCJERCI6IjVlZDBlMGQ4NmFjNzgwMmM4MDM3NGZkZSIsIlNUIjoxNTkwNzQ3MzUyNzQ3LCJpYXQiOjE1OTA3NDczNTJ9.DfIzb9C9mKraI2l1sBQMvlnRWK1XDVasnH_Rjw2AaKE'
                target="_blank"><img src={discount} alt='Get an Offer'/></a>
              {/*<p>to start your online store</p>*/}
            </div>
          </Col>
          {/*</Row>*/}

          {/*<Col lg={{offset:5, span:7}}  md={6}>*/}
          <Col lg={7} md={6}>
            <GuestUser/>
            <BillingInfo isGuest={true}/>
            <DeliveryInstructions/>
            <PaymentMethods/>
          </Col>
        </Row>
      </Container>

      <div className='discount-bar'>
        <Container fluid={false}>
          <Row>
            <Col md={9}>
              <p className='discount-title'>Get a discount coupon to start your web store.</p>
            </Col>
            <Col md={3} className='text-center'>
              <a
                href='https://optin.spencehub.com/nextgen-web-store-that-generate-predictable-income-sri-lanka?sid=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IiIsIlNDIjoic3RvcmUiLCJERCI6IjVlZDBlMTBjZTAzOWUzMmNiOTljZGYzNyIsIlNUIjoxNTkwNzQ3NDA0NDYwLCJpYXQiOjE1OTA3NDc0MDR9.hDqHTmOhZjxhM1FrRqMgSAyzWPgIcgoqLqGT6cBPrkI'
                className='discount-link' target='_blank'>
                Click here
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Checkout;