export const listItemsColumns = [
    {
        dataField: "key",
        text: "#",
        sort: false,
        classes: 'small-column' //these classes are added to td only, not th
    },
    {
        dataField: "itemPhoto",
        text: "Item",
        sort: false,
        classes: 'text-center'
    },
    {
        dataField: "name",
        text: "Name",
        sort: true,
        classes: 'text-left text-bold'
    },
    {
        dataField: "qty",
        text: "Qty",
        sort: false,
        classes: 'text-center'
    },
    {
        dataField: "unitPrice",
        text: "Unit Price",
        sort: false,
        classes: 'text-center'
    },
    {
        dataField: "amount",
        text: "Amount",
        sort: false,
        classes: 'text-center'
    },
    {
        dataField: "removeIcon",
        text: "",
        sort: false,
        classes: 'small-column'
    }
];