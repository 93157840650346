import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import popup from '../../assets/images/pop up.png';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {setWelcomeCount} from "../../store/actions/itemActions";

const PopUpModel: React.FC = () => {
  const welcomeCount: number = useSelector((state: AppState) => state.item.welcomeCount);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  useEffect(() => {
    const isShow = welcomeCount < 4 && welcomeCount % 2 === 0;
    setShow(isShow);

    if (welcomeCount < 7) {
      dispatch(setWelcomeCount());
    }
  }, []);

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} size="lg" dialogClassName={'welcome-modal'}>

      <div className="pop-up">
        <img src={popup}/>
      </div>
      <div>
        <i className="feather icon-x" onClick={handleClose}/>
      </div>
    </Modal>
  );
};

export default PopUpModel;