export const FE_ROUTES = {
    THANKYOU: '/thankyou',
    REGISTER: '/register',
    LOGIN: '/login',
    MYACCOUNT: '/myaccount',
    CHECKOUT: '/checkout',
    CONTACTUS: '/contact-us',
    ABOUTUS: '/about-us',
    FAQ: '/faq',
    PRICING: '/pricing',
    DEMO: '/demo',
    HOME: '/',
    ROOT: "/:title?"
};

export const API_ROUTES = {};