import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Row} from "react-bootstrap";
import {Redirect} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setRedirect} from "../../store/actions/clientActions";

const GuestUser: React.FC = () => {
  const [isLogin, setIsLogin] = useState(false); //go to signin
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRedirect(null));
  },[]);

  const handleLoginRequested = async () => {
    await dispatch(setRedirect('/paynow'));
    setIsLogin(true)
  };

  const handleSignRedirect = () => {
    // return (<Redirect strict from='/paynow' to='/login'  />);
    return <Redirect to='#' />;
  };

  return (
    <Card className='payment-card'>
      {isLogin && handleSignRedirect()}

      <Card.Body>
        <Row>
          <Col md={7} xs={9} className='pr-0'>
            <p className='title-text pt-1'>Already have an account?</p>
          </Col>
          <Col xs={6} md={3} className='pl-md-4 pr-md-0 pl-4 click-button'>
              <Button onClick={handleLoginRequested}>
                Sign in
              </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default GuestUser;