import React from 'react';

function AboutUs() {
    return (
        <div className="text-center mt-5 mb-5">
            <h1>About US</h1>
        </div>
    )
}

export default AboutUs;