import React, {useEffect, useState} from 'react';
import {Col, Form, InputGroup} from "react-bootstrap";
import PasswordStrength from "./PasswordStrength";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {NewUser} from "../../types/bookingStoreTypes/bookingStoreTypes";
import {setUserDetails} from "../../store/actions/bookingActions";

type BillingInfoRegisterProps = {
  isValidated: boolean
}

const BillingInfoRegister: React.FC<BillingInfoRegisterProps> = (props) => {
  const {isValidated} = props;

  const initState = {email: '', reEmail: '', password: ''};
  const [info, setInfo] = useState(initState);
  const [isSameEmail, setIsSameEmail] = useState(true);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const newUser: NewUser | null = useSelector((state: AppState) => state.booking.newUer);
  const dispatch = useDispatch();

  let newUserDetails: NewUser;
  useEffect(() => {
    newUserDetails = newUser ? newUser : {
      email: null,
      password: null
    };
  });

  const handleOnShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const checkEmailsForEqual = () => {
    if (info.email.trim() === info.reEmail.trim()) {
      setIsSameEmail(true);
      dispatch(setUserDetails({...newUserDetails, email: info.email.trim()}));
      // return;
    } else if (info.reEmail.trim().length > 1) {
      dispatch(setUserDetails({...newUserDetails, email: null}));
      setIsSameEmail(false);
    }
  };

  const handleEmailChange = async (email: string) => {
    await setInfo({...info, email: email});
  };

  const handleRetypeEmailChange = async (email: string) => {
    await setInfo({...info, reEmail: email});
  };

  const handlePasswordChange = (password: string) => {
    setInfo({...info, password: password});
    // dispatch(setUserDetails({...newUserDetails, password: password}));
  };

  return (
    <Form.Row>
      <Form.Group as={Col} md="6" controlId="validationCustomEmail">
        <Form.Label>Email*</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="Email"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailChange(e.target.value)}
          onBlur={checkEmailsForEqual}
        />
        {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
        <Form.Control.Feedback type="invalid">
          Please provide your correct email
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="6" controlId="validationCustomEmailRepeat">
        <Form.Label>Retype Email*</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder=""
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRetypeEmailChange(e.target.value)}
          onBlur={checkEmailsForEqual}
        />
        {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
        <Form.Control.Feedback
          className={!isSameEmail ? 'dis-block' : ''}
          type="invalid">
          Please type same email
        </Form.Control.Feedback>
      </Form.Group>


      <Form.Group as={Col} md="12" className='password-group' controlId="validationCustomPassword">
        <Form.Label>Choose your password*</Form.Label>
        <InputGroup className='password-append'>
          <Form.Control
            required
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder=""
            isInvalid={isValidated && newUser !== null && newUser.password === null}
            className='append-control'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePasswordChange(e.target.value)}
          />
          <InputGroup.Append>
            <InputGroup.Text onClick={handleOnShowPassword}>
              <i className={`feather ${isPasswordVisible ? 'icon-eye-off' : 'icon-eye'}`}/>
            </InputGroup.Text>
          </InputGroup.Append>


          {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
          <Form.Control.Feedback
            className={isValidated && newUser && !newUser.password ? 'dis-block' : ''}
            type="invalid">
            Please type a strong password
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <PasswordStrength password={info.password} newUser={newUser}/>

      {/*<Form.Group as={Col} md="12" controlId="validationCustomPasswordRetype">*/}
      {/*  <Form.Label>Re-type password*</Form.Label>*/}
      {/*  <Form.Control*/}
      {/*    required*/}
      {/*    type="password"*/}
      {/*    placeholder=""*/}
      {/*  />*/}
      {/*  /!*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*!/*/}
      {/*  <Form.Control.Feedback type="invalid">*/}
      {/*    Please re-type the password*/}
      {/*  </Form.Control.Feedback>*/}
      {/*</Form.Group>*/}

    </Form.Row>
  );
};

export default BillingInfoRegister;