import {Item} from "../types/itemStoreTypes";

const baseURL = 'https://s3.amazonaws.com/cdn1.shub';

export const products: Item[]
  = [
  {
    id: 1,
    itemPhoto: baseURL + '/demo/home/1.jpg',
    name: 'Cordless Chainsaw',
    price: 160.65,
    discount: 190.00,
    qty: 0
  },
  {
    id: 2,
    itemPhoto: baseURL + '/demo/home/2.jpg',
    name: 'Cordless Brushless Impact Wrench ',
    price: 139.86,
    discount: 160.00,
    qty: 0
  },
  {
    id: 3,
    itemPhoto: baseURL + '/demo/home/3.jpg',
    name: 'Cordless Vacuum Cleaner',
    price: 144.00,
    discount: 180.00,
    qty: 0
  },
  {
    id: 4,
    itemPhoto: baseURL + '/demo/home/4.jpg',
    name: 'Brushless Cordless Hammer Drill',
    price: 138.60,
    discount: 150.00,
    qty: 0
  },
  {
    id: 6,
    itemPhoto: baseURL + '/demo/home/5.jpg',
    name: '300W Multi Tool',
    price: 249.99,
    discount: 400.00,
    qty: 0
  },
  {
    id: 7,
    itemPhoto: baseURL + '/demo/home/6.jpg',
    name: 'cinnamon',
    price: 225.00,
    discount: 250.00,
    qty: 0
  },
  {
    id: 8,
    itemPhoto: baseURL + '/demo/home/7.jpg',
    name: 'Cordless Angle Grinder',
    price: 89.70,
    discount: 95.00,
    qty: 0
  },
  {
    id: 9,
    itemPhoto: baseURL + '/demo/home/8.jpg',
    name: 'Cordless Angle Grinder',
    price: 246.57,
    discount: 250.00,
    qty: 0
  },
];

export const productsFruits: Item[]
  = [
  {
    id: 21,
    itemPhoto: baseURL + '/demo/home/9.jpg',
    name: 'Rotary Hammer Drill',
    price: 148.50,
    discount: 190.00,
    qty: 0
  },
  {
    id: 22,
    itemPhoto: baseURL + '/demo/home/10.jpg',
    name: 'Ryobi Rotary Hammer Drill',
    price: 259.00,
    discount: 290.00,
    qty: 0
  },
  {
    id: 23,
    itemPhoto: baseURL + '/demo/home/11.jpg',
    name: 'AEG Rotary Hammer Drill',
    price: 640.00,
    discount: 790.00,
    qty: 0
  },
  {
    id: 24,
    itemPhoto: baseURL + '/demo/home/12.jpg',
    name: 'Makita Brushless Hammer Drill',
    price: 282.00,
    discount: 290.00,
    qty: 0
  },
  {
    id: 25,
    itemPhoto: baseURL + '/demo/home/13.jpg',
    name: 'AEG 800W Hammer Drill',
    price: 256.00,
    discount: 290.00,
    qty: 0
  },
  {
    id: 26,
    itemPhoto: baseURL + '/demo/home/14.jpg',
    name: 'Makita Corded Hammer Drill',
    price: 790.00,
    discount: 850.00,
    qty: 0
  },
  {
    id: 27,
    itemPhoto: baseURL + '/demo/home/15.jpg',
    name: 'Makita Hammer Drill',
    price: 179.00,
    discount: 190.00,
    qty: 0
  },
  {
    id: 28,
    itemPhoto: baseURL + '/demo/home/16.jpg',
    name: 'Ryobi One+ Hammer Drill',
    price: 172.55,
    discount: 179.00,
    qty: 0
  },
  // {
  //   id: 29,
  //   itemPhoto: baseURL + '/demo/home/mango.jpg',
  //   name: 'mango',
  //   price: 55.00,
  //   discount: 90.00,
  //   qty: 0
  // },
  // {
  //   id: 30,
  //   itemPhoto: baseURL + '/demo/home/strawberry.jpg',
  //   name: 'strawberry',
  //   price: 55.00,
  //   discount: 90.00,
  //   qty: 0
  // },


];

export const productsVegetables: Item[]
  = [
  {
    id: 41,
    itemPhoto: baseURL + '/demo/home/17.jpg',
    name: 'Ryobi One+ 18V 1.5Ah',
    price: 122.10,
    discount: 130.00,
    qty: 0
  },
  {
    id: 42,
    itemPhoto: baseURL + '/demo/home/18.jpg',
    name: 'Ozito PXC 18V 4.0Ah Lithium-Ion',
    price: 98.67,
    discount: 110.00,
    qty: 0
  },
  {
    id: 43,
    itemPhoto: baseURL + '/demo/home/19.jpg',
    name: 'Makita CXT 12V 4.0Ah Max Li-Ion Battery',
    price: 137.95,
    discount: 230.00,
    qty: 0
  },
  {
    id: 44,
    itemPhoto: baseURL + '/demo/home/20.jpg',
    name: 'DeWALT 18/54V 12.0Ah XR Flexvolt Battery',
    price: 409.12,
    discount: 512.20,
    qty: 0
  },
  {
    id: 45,
    itemPhoto: baseURL + '/demo/home/21.jpg',
    name: 'AEG 18V 9.0Ah FORCE Battery',
    price: 303.05,
    discount: 324.00,
    qty: 0
  },
  {
    id: 46,
    itemPhoto: baseURL + '/demo/home/22.jpg',
    name: 'DeWALT 12V XR Li-Ion 3.0Ah Battery',
    price: 122.60,
    discount: 140.00,
    qty: 0
  },
  {
    id: 47,
    itemPhoto: baseURL + '/demo/home/23.jpg',
    name: 'DeWALT 18V XR Li-Ion 4.0Ah Battery',
    price: 189.95,
    discount: 280.00,
    qty: 0
  },

];


export const productsPharmacy: Item[]
  = [
  {
    id: 200,
    itemPhoto: baseURL + '/demo/art/1.jpg',
    name: 'DEWALT Rapid Heat Ceramic Glue Gun',
    price: 62.67,
    discount: 92.20,
    qty: 0
  },
  {
    id: 201,
    itemPhoto: baseURL + '/demo/art/2.jpg',
    name: 'Flexovit 115mm x 5m 80 Grit Painted Sandpaper Roll',
    price: 32.00,
    discount: 34.00,
    qty: 0
  },
  {
    id: 202,
    itemPhoto: baseURL + '/demo/art/3.jpg',
    name: 'EnduraSeal 100m Thread Sealing String',
    price: 36.01,
    discount: 44.05,
    qty: 0
  },
  {
    id: 203,
    itemPhoto: baseURL + '/demo/art/4.jpg',
    name: 'Scotch 2.5cm x 1.5m Extreme Double Sided Mounting Tape',
    price: 31.81,
    discount: 42.00,
    qty: 0
  },
  {
    id: 204,
    itemPhoto: baseURL + '/demo/art/5.jpg',
    name: 'Flexovit 115mm x 5m 180 Grit Painted Sandpaper Roll',
    price: 32.00,
    discount: 44.00,
    qty: 0
  },
  {
    id: 205,
    itemPhoto: baseURL + '/demo/art/6.jpg',
    name: 'Scotch 2.5cm X 1.5m Clear Double Sided Mounting Tape',
    price: 28.68,
    discount: 30.00,
    qty: 0
  },
  {
    id: 206,
    itemPhoto: baseURL + '/demo/art/7.jpg',
    name: 'Scotch 7.6 x 2.5cm Extreme Double Sided Mounting Strips',
    price: 26.26,
    discount: 29.00,
    qty: 0
  },
  {
    id: 207,
    itemPhoto: baseURL + '/demo/art/8.jpg',
    name: 'Scotch 2.5cm x 11.4m Outdoor Double Sided Mounting Tape',
    price: 61.17,
    discount: 2660.00,
    qty: 0
  },
  {
    id: 208,
    itemPhoto: baseURL + '/demo/art/9.jpg',
    name: 'Dremel 930 Dual Temperature Glue Gun With Glue Sticks',
    price: 64.99,
    discount: 68.80,
    qty: 0
  },
  {
    id: 209,
    itemPhoto: baseURL + '/demo/art/10.jpg',
    name: 'Scotch 2.5cm x 11.4m Clear Double Sided Mounting Tape',
    price: 56.24,
    discount: 60.99,
    qty: 0
  },

];

export const productsFood: Item[]
  = [
  {
    id: 100,
    itemPhoto: baseURL + '/food/chicken_burgur.jpg',
    name: 'Burger',
    price: 385.00,
    discount: 490.00,
    qty: 0
  },
  {
    id: 101,
    itemPhoto: baseURL + '/food/creemy_cheese_sasuage_pizza.jpg',
    name: 'Pizza',
    price: 690.00,
    discount: 750.00,
    qty: 0
  },
  {
    id: 102,
    itemPhoto: baseURL + '/food/grilled_sausages.jpg',
    name: 'Grill Sausages',
    price: 190.00,
    discount: 250.00,
    qty: 0
  },
  {
    id: 103,
    itemPhoto: baseURL + '/food/ham_and_cheese_submarine.jpg',
    name: 'Submarine',
    price: 390.00,
    discount: 450.00,
    qty: 0
  },
  {
    id: 104,
    itemPhoto: baseURL + '/food/prawn_pasta.jpg',
    name: 'Pasta',
    price: 190.00,
    discount: 250.00,
    qty: 0
  },
  {
    id: 105,
    itemPhoto: baseURL + '/food/smoked_fish.jpg',
    name: 'Smoked Fish',
    price: 690.00,
    discount: 750.00,
    qty: 0
  },
  {
    id: 106,
    itemPhoto: baseURL + '/food/Staek_with_mushrooms.jpg',
    name: 'Mushroom',
    price: 250.00,
    discount: 350.00,
    qty: 0
  },
  {
    id: 107,
    itemPhoto: baseURL + '/food/vegetable_rice.jpg',
    name: 'Vegi Fried Rice',
    price: 300.00,
    discount: 350.00,
    qty: 0
  },


];

export const productsCake: Item[]
  = [
  {
    id: 110,
    itemPhoto: baseURL + '/cakes/choclate_muffins.jpg',
    name: 'Chocolate Muffins',
    price: 85.00,
    discount: 90.00,
    qty: 0
  },
  {
    id: 111,
    itemPhoto: baseURL + '/cakes/coffee_pudding.jpg',
    name: 'Coffee Pudding',
    price: 85.00,
    discount: 90.00,
    qty: 0
  },
  {
    id: 112,
    itemPhoto: baseURL + '/cakes/mirror_glazed_cake.jpg',
    name: 'Mirror Glazed Cake',
    price: 485.00,
    discount: 590.00,
    qty: 0
  },
  {
    id: 113,
    itemPhoto: baseURL + '/cakes/naked_cake.jpg',
    name: 'Naked Cake',
    price: 285.00,
    discount: 390.00,
    qty: 0
  },
  {
    id: 114,
    itemPhoto: baseURL + '/cakes/strawberry_shortcake.jpg',
    name: 'Strawberry Shortcake',
    price: 485.00,
    discount: 590.00,
    qty: 0
  },
  {
    id: 115,
    itemPhoto: baseURL + '/cakes/Sugar buns.jpg',
    name: 'Sugar buns',
    price: 155.00,
    discount: 190.00,
    qty: 0
  },
  {
    id: 116,
    itemPhoto: baseURL + '/cakes/Tirramusu.jpg',
    name: 'Tirramusu',
    price: 125.00,
    discount: 170.00,
    qty: 0
  },
  {
    id: 116,
    itemPhoto: baseURL + '/cakes/Vannila cupcakes.jpg',
    name: 'Vannila cupcakes',
    price: 90.00,
    discount: 100.00,
    qty: 0
  },


];

export const productsElectronics: Item[]
  = [
  {
    id: 300,
    itemPhoto: baseURL + '/electro/doughnut.jpg',
    name: 'doughnut maker',
    price: 3085.00,
    discount: 3390.00,
    qty: 0
  },
  {
    id: 301,
    itemPhoto: baseURL + '/electro/burner.jpg',
    name: 'Gas burner',
    price: 10085.00,
    discount: 13390.00,
    qty: 0
  },
  {
    id: 302,
    itemPhoto: baseURL + '/electro/air_conditioner.jpg',
    name: 'air conditioner',
    price: 60000.00,
    discount: 83000.00,
    qty: 0
  },
  {
    id: 303,
    itemPhoto: baseURL + '/electro/ceiling_fan.jpg',
    name: 'ceiling fan',
    price: 6000.00,
    discount: 8000.00,
    qty: 0
  },
  {
    id: 304,
    itemPhoto: baseURL + '/electro/electric_oven.jpg',
    name: 'electric oven',
    price: 11000.00,
    discount: 13000.00,
    qty: 0
  },
  {
    id: 305,
    itemPhoto: baseURL + '/electro/floor_polisher.jpg',
    name: 'floor polisher',
    price: 18000.00,
    discount: 25000.00,
    qty: 0
  },
  {
    id: 306,
    itemPhoto: baseURL + '/electro/food_processor.jpg',
    name: 'food processor',
    price: 12000.00,
    discount: 15000.00,
    qty: 0
  },
  {
    id: 307,
    itemPhoto: baseURL + '/electro/grinder.jpg',
    name: 'grinder',
    price: 8000.00,
    discount: 19000.00,
    qty: 0
  },
  {
    id: 308,
    itemPhoto: baseURL + '/electro/hot_plate.jpg',
    name: 'hot plate',
    price: 1800.00,
    discount: 2500.00,
    qty: 0
  },
  {
    id: 309,
    itemPhoto: baseURL + '/electro/nonstick_pan.jpg',
    name: 'nonstick pan',
    price: 1600.00,
    discount: 2500.00,
    qty: 0
  },
  {
    id: 310,
    itemPhoto: baseURL + '/electro/panni_press.jpg',
    name: 'panni press',
    price: 11800.00,
    discount: 12500.00,
    qty: 0
  },
  {
    id: 311,
    itemPhoto: baseURL + '/electro/table_fan.jpg',
    name: 'table fan',
    price: 2800.00,
    discount: 4500.00,
    qty: 0
  },
  {
    id: 312,
    itemPhoto: baseURL + '/electro/television.jpg',
    name: 'television',
    price: 22800.00,
    discount: 45000.00,
    qty: 0
  },
  {
    id: 313,
    itemPhoto: baseURL + '/electro/toaster.jpg',
    name: 'toaster',
    price: 2800.00,
    discount: 4500.00,
    qty: 0
  },
  {
    id: 314,
    itemPhoto: baseURL + '/electro/waffel_maker.jpg',
    name: 'waffel maker',
    price: 3700.00,
    discount: 5500.00,
    qty: 0
  },
  {
    id: 314,
    itemPhoto: baseURL + '/electro/washin_machine.png',
    name: 'washing machine',
    price: 77000.00,
    discount: 155000.00,
    qty: 0
  },



];

