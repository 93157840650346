export const customDropdownStylesNoColor = {
    dropdownIndicator: (provided: any) => ({
        ...provided,
        // color: '#ffffff',
        // color: '#00aba9',
        color: '#9E9E9E',
        paddingTop: 3,
        paddingBottom: 3
    }),
    control: (provided: any) => {
        return {
            ...provided,
            borderRadius: 3,
            borderColor: '#9E9E9E',
            minHeight: 23,
            paddingTop: 2,
            paddingBottom: 2,
            fontSize: '.8em'
        };
    },
    indicatorsContainer: (provided: any, state: any) => {
        return {
            ...provided,
            // backgroundColor: '#00aba9',
            minHeight: 25,

        };
    },

    valueContainer: (provided: any, state: any) => {
        return {
            ...provided,
            // backgroundColor: '#00aba9',
            paddingTop: 0,
            paddingBottom: 0
        };
    },
    menu: (provided: any, state: any) => {
        return {
            ...provided,
            marginTop: 1,
        };
    },
    option: (provided: any, state: any) => {
        console.log(provided);
        return {
            ...provided,
            fontSize: '.8rem',
            paddingTop: 2,
            paddingBottom: 2
        };
    },
};
