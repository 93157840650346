import React, {useEffect, useState} from 'react';
import {Col, Row, Form, Button} from 'react-bootstrap';
import img from '../../assets/images/ezgif.com-webp-to-jpg (1).jpg';
import {Item} from "../../types/itemStoreTypes";
import {Image} from "react-feather";
import {useDispatch, useSelector} from "react-redux";
import {addItem, changeItem} from "../../store/actions/itemActions";
import NumberFormat from "react-number-format";
import {smallCents, smallCentsWithPrefix} from "../../util/uiComponents";
import {AppState} from "../../store/reducers";
import {getCurrencyLabel} from "../../util/common";

// type ProductProps = {
//     id: number
//     itemPhoto: string
//     name: string
//     price: number
//     discount: number
//     qty?: number
// }

type ProductProps = {
    item: Item
    itemsInCart: Item[]
}

function Product(props: ProductProps) {
    const {itemPhoto, name, price, discount} = props.item;
    const {itemsInCart, item} = props;
    const dispatch = useDispatch();

    const [qty, setQty] = useState<number>(1);

    const itemInCart = itemsInCart.find((cartItem: Item) => cartItem.id === item.id);

    const currencyCode: string = useSelector((state: AppState) => state.item.geo.currency);
    const [currency, setCurrency] = useState<string>(getCurrencyLabel(currencyCode));

    useEffect(() => {
        setCurrency(getCurrencyLabel(currencyCode));
    }, [currencyCode]);

    /**
     * on add button click adds product to store.
     */
    const handleProductAdd = () => {
      dispatch(addItem({...props.item, qty: qty}));
    };

    const handleProductUpdate = () => {
      dispatch(changeItem({...props.item, qty: qty}));
    };

    const handleChangeQty = (value: string) => {
        setQty(parseFloat(value))
    };

    return (
        <Col xs={6} sm={6} md={4} lg={3}>
            <div className="product-bg product-img">
                <img src={itemPhoto} alt={name}/>
                <p>{name}</p>
                <Row>
                    <Col xs={6} className='prices'>
                        <label className="product-price-discount">
                            <NumberFormat value={discount} thousandSeparator={true} displayType='text'
                                          decimalScale={2} fixedDecimalScale={true} />
                        </label>
                    </Col>
                    <Col xs={6} className='prices pl-0'>
                        <label className="product-price">
                            <NumberFormat value={price} thousandSeparator={true} displayType='text'
                                          prefix={currency + ' '}
                                          decimalScale={2} fixedDecimalScale={true}
                                          renderText={smallCentsWithPrefix}
                            />
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} className="pr-lg-0 pr-md-0 pb-xs-1 mb-1">
                        <Form>
                            <Form.Control type="number" placeholder="0" min="0"
                                          value={qty}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                              handleChangeQty(e.target.value)}   />
                        </Form>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} className="add-to-cart-btn">
                        {!itemInCart && <Button onClick={handleProductAdd} className='add-btn'>
                            Add To Cart
                        </Button>}

                        {itemInCart && <Button variant='outline-info' className='update-btn'
                        onClick={handleProductUpdate}>
                            Update
                        </Button>}

                    </Col>
                </Row>
            </div>
        </Col>

    )
}

export default Product;