import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from "react-bootstrap";
import PreviewItem from "./PreviewItem";
import {Redirect} from "react-router-dom";
import img from '../../assets/images/ezgif.com-webp-to-jpg (1).jpg';
import {useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {Item} from "../../types/itemStoreTypes";
import NumberFormat from "react-number-format";
import emptyCartIcon from '../../assets/images/empty-cart.png';
import {getCurrencyLabel} from "../../util/common";

const items = [
  img
];

const ShoppingCart: React.FC = () => {
  //useSelector to get values from store (AppState - accesss many stores we have) (state.item - reducer)
  const items: Item[] = useSelector((state: AppState) => state.item.items);

  const [isRedirect, setIsRedirect] = useState(false);

  const currencyCode: string = useSelector((state: AppState) => state.item.geo.currency);
  const [currency, setCurrency] = useState<string>(getCurrencyLabel(currencyCode));

  useEffect(() => {
    setCurrency(getCurrencyLabel(currencyCode));
  }, [currencyCode]);

  const listItemsInCart = () => {
    // console.log(items);

    return items.map((item: Item, index: number) => <PreviewItem item={item} key={index}/>);
  };

  // const total = items.reduce((total: number, b: Item) => total + b.price, 0);
  const totalPrice = items.reduce((total: number, b: Item) => total + (b.price * b.qty), 0);

  const getCartSummary = () => {
    return (
      <React.Fragment>
        {isRedirect && <Redirect to='/checkout'/>}

        <Row>
          <Col xs={6} sm={6} md={6} lg={6} className="mt-2">
            <label className="cart-item-subtotal-title">Subtotal ({items.length} Items)</label>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} className="mt-2">
            <label className="cart-item-subtotal">
              <NumberFormat value={totalPrice} thousandSeparator={true} displayType='text'
                            prefix={currency + ' '}
                            decimalScale={2} fixedDecimalScale={true}
              />
            </label>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6} className=" mb-2">
            <label className="cart-item-subtotal-title">Discount</label>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <label className="cart-item-discount">
              <NumberFormat value={0} thousandSeparator={true} displayType='text'
                            prefix={currency + ' '}
                            decimalScale={2} fixedDecimalScale={true}
              />
            </label>
          </Col>
        </Row>
        <div className="divider"/>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6} className="mt-2 mb-2">
            <label className="cart-item-subtotal-title">Est.Total</label>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} className="mt-2 mb-2">
            <label className="cart-item-subtotal">
              <NumberFormat value={totalPrice} thousandSeparator={true} displayType='text'
                            prefix={currency + ' '}
                            decimalScale={2} fixedDecimalScale={true}
              />
            </label>
          </Col>
        </Row>
        <div className="add-to-cart-btn mt-2">
          {/*<Link to='/checkout'>*/}
          <Button className="add-btn" onClick={() => setIsRedirect(true)}>Checkout</Button>
          {/*</Link>*/}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Row>
        <Col className='cart-height'>
          {listItemsInCart()}
          {/*<PreviewItem item={products[0]}/>*/}
        </Col>
      </Row>

      {items.length > 0 && getCartSummary()}
      {items.length === 0 &&
      <div className="empty-cart">
        <img src={emptyCartIcon} alt="empty cart"/>
        <p>Your Cart is empty</p>
        <label>Add items to your cart :)</label>
      </div>
      }
    </div>
  );
};

export default ShoppingCart;