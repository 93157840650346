import React from 'react';

function Login() {
    return (
        <div className="text-center mt-5 mb-5">
            <h1>Login Page</h1>
        </div>
    )
}

export default Login;