import React from 'react';
import img from '../../assets/images/slide.jpg';
import { Container } from "react-bootstrap";

function Slider() {
    return (
        <div className="slider">
            <img src={img} />
        </div>
    )
}

export default Slider;