import {Geo, ItemActionTypes, ItemState} from "../../types/itemStoreTypes";
import {ADD_ITEM, CHANGE_ITEM, REMOVE_ITEM, SET_CATEGORY, SET_DEMO_CATEGORY} from "../../constants/actions/ItemActions";
import {SET_GEO, SET_WELCOME_COUNT} from "../../constants/actions/actions";
import {INIT_VISITOR_COUNTRY} from "../../constants/values";

const geo: Geo = {
  countryName: INIT_VISITOR_COUNTRY, //US for identify init,
  currency: '$',
  callingCode: '+1',
  countryCode: 'US'
};

const itemInitialState: ItemState = {
  items: [],
  // category: null
  category: 'grocery',
  demoCategory: 'Power Tools',
  welcomeCount: 0,
  geo: geo
};

export function itemReducer(
  state = itemInitialState,
  action: ItemActionTypes
): ItemState {
  switch (action.type) {
    case ADD_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload]
      };
    case REMOVE_ITEM:
      return {
        ...state,
        items: state.items.filter(
          item => item.id !== action.payload
        )
      };
    case CHANGE_ITEM: {
      const items = state.items.slice();
      const indexOfUpdatedItem = items.findIndex(item => item.id === action.payload.id);
      items[indexOfUpdatedItem] = action.payload;
      return {
        ...state,
        items: items
      };
    }
    case SET_CATEGORY: {
        return {
            ...state,
            category: action.payload
        }
    }
    case SET_DEMO_CATEGORY: {
        return {
            ...state,
            demoCategory: action.payload
        }
    }
    case SET_WELCOME_COUNT: {
        return {
            ...state,
            welcomeCount: state.welcomeCount + 1
        }
    }
    case SET_GEO: {
        return {
            ...state,
            geo: action.payload
        }
    }
    default: {
      return state;
    }
  }
}