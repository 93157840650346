import React, {useState} from 'react';
import {Col, Container, Row, Alert} from "react-bootstrap";
import Category from "./Category";

const Categories: React.FC = () => {
  const imgAll = '';
  const imgGrocery = 'https://s3.amazonaws.com/cdn1.shub/categories/grocery.png';
  const imgPharmacy = 'https://s3.amazonaws.com/cdn1.shub/categories/health.png';
  const imgFood = 'https://s3.amazonaws.com/cdn1.shub/categories/food.png';
  const imgElectro = 'https://s3.amazonaws.com/cdn1.shub/categories/electro.png';

  const [showAlert, setShowAlert] = useState(true);


  return (
    <Container fluid={false}>
      <Row>
        <Col>
          <Alert variant='info' show={showAlert} className='product-alert'
                 onClose={() => setShowAlert(false)}
                 dismissible={true}>
            *Products listed here are demo purpose only. They are not available for sale.
          </Alert>
        </Col>
      </Row>
      <div className="our-product-txt">
        <h1>Demo Products</h1>
      </div>
      <Row className="justify-content-around">
        <Category category={'all'} img={imgAll}/>
        <Category category={'grocery'} img={imgGrocery}/>
        <Category category={'pharmacy'} img={imgPharmacy}/>
        <Category category={'food'} img={imgFood}/>
        <Category category={'electronic'} img={imgElectro}/>
      </Row>
    </Container>
  );
};

export default Categories;