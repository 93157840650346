import React from 'react';
import {useDispatch} from "react-redux";
import {removeItem} from "../../../store/actions/itemActions";
import {Item} from "../../../types/itemStoreTypes";

type RemoveIconProps = {
    item: Item
}

const RemoveIcon: React.FC<RemoveIconProps> = (props) => {
    const dispatch = useDispatch();
    const {item} = props;

    const handleRemoveItem = () => {
        dispatch(removeItem(item.id));
    };

    return (
        <div>
            <i className="feather icon-trash"
            onClick={handleRemoveItem} />
        </div>
    )

};

export default RemoveIcon;