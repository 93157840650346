import React, {useEffect, useState} from 'react';
import {Row, Col} from "react-bootstrap";
import img from '../../assets/images/ezgif.com-webp-to-jpg (1).jpg';
import {Item} from "../../types/itemStoreTypes";
import {useDispatch, useSelector} from "react-redux";
import {removeItem} from "../../store/actions/itemActions";
import NumberFormat from "react-number-format";
import {smallCentsWithPrefix} from "../../util/uiComponents";
import RemoveIcon from "../Checkout/cartRow/RemoveIcon";
import {AppState} from "../../store/reducers";
import {getCurrencyLabel} from "../../util/common";

type ItemPreviewProps = {
  item: Item
}

const PreviewItem: React.FC <ItemPreviewProps> = (props) => {
    const dispatch = useDispatch();

    // const { productImg, productName, qty, productPrice } = props;
    const {item} = props;

    const currencyCode: string = useSelector((state: AppState) => state.item.geo.currency);
    const [currency, setCurrency] = useState<string>(getCurrencyLabel(currencyCode));

    useEffect(() => {
        setCurrency(getCurrencyLabel(currencyCode));
    }, [currencyCode]);

    if (item.qty === undefined) {
        return <React.Fragment/>
    }

    // const handleRemoveItem = () => {
    //     dispatch(removeItem(item.id));
    // };

    return (
        <div>
            <Row>
                <Col xs={4} sm={4} md={4} lg={4} className="mb-2 mt-2">
                    <div className="cart-item">
                        <img src={item.itemPhoto} alt={item.name}/>
                    </div>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                    <div className="cart-item-name">
                        <label title={item.name}>{item.name}</label>
                    </div>
                    <div className="cart-item-qty">
                        <label>Qty: {item.qty}</label>
                    </div>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                    <div>
                        {/*<i className="feather icon-trash"*/}
                        {/*   onClick={handleRemoveItem}/>*/}
                        
                        <RemoveIcon item={item}/>
                    </div>
                    <div className="cart-item-price">
                        <label>
                            <NumberFormat value={item.price * item.qty} thousandSeparator={true} displayType='text'
                                          prefix={currency + ' '}
                                          decimalScale={2} fixedDecimalScale={true}
                                          renderText={smallCentsWithPrefix}
                            />

                        </label>
                    </div>
                </Col>
            </Row>
            <div className="divider"/>
        </div>
    )
};

export default PreviewItem;