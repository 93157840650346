import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import StrengthBar from "./StrengthBar";
import {useDispatch} from "react-redux";
import {NewUser} from "../../types/bookingStoreTypes/bookingStoreTypes";
import {setUserDetails} from "../../store/actions/bookingActions";

type PasswordStrengthProps = {
  password: string
  newUser: NewUser | null
}

const PasswordStrength: React.FC<PasswordStrengthProps> = (props) => {
  const {password, newUser} = props;
  const dispatch = useDispatch();

  let newUserDetails: NewUser;
  useEffect(() => {
    newUserDetails = newUser ? newUser : {
      email: null,
      password: null
    };
  });

  const [strength, setStrength] = useState(0); //possible values are [0 - 6] only.
  const colors = ['', 'bg-danger', 'bg-warning', 'bg-success'];

  const calcStrength = () => {
    let strengthVal = [/[$@$!%*#?&]/, /[A-Z]/, /[0-9]/, /[a-z]/]
      .reduce((val, test) => val + (test.test(password) ? 1 : 0), 0);

    // Length must be at least 8 chars
    if(strengthVal > 2 && password.length > 7){
      strengthVal++;

      // Length 10 with all tests passed, perfect pw.
      if (strengthVal > 3 && password.length > 9) {
        strengthVal++;
      }
    }

    //length should be at least 6
    if (password.length < 6 && strengthVal > 3) {
      strengthVal = 3;
    }

    return strengthVal;
  };

  const dispatchPassword = (pw: string | null) => {
    dispatch(setUserDetails({...newUserDetails, password: pw}));
  };

  useEffect(() => {
    // const strengthValue = password.length > 6 ? 6 : password.length;
    const strengthValue = calcStrength();
    setStrength(strengthValue);

    dispatchPassword(strengthValue > 3 ? password : null);
  }, [password]);

  const getBars = () => {
    let statusColor = '';
    switch (strength) {
      case 1: statusColor = colors[1]; break;
      case 2: statusColor = colors[1]; break;
      case 3: statusColor = colors[2]; break;
      case 4: statusColor = colors[2]; break;
      case 5: statusColor = colors[3]; break;
      case 6: statusColor = colors[3]; break;
      default: statusColor = colors[0];
    }

    return (
        [...Array(6)].map((e, index) => {
          return <StrengthBar status={index + 1 <= strength ? statusColor : ''} key={index}/>
        })
    )
  };

  return (
    <Col md={12} className='strength-bars'>
      {getBars()}
    </Col>
  );
};

export default PasswordStrength;